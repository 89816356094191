import React, { useState } from 'react';
import './styles/pfp.scss';
import axios from 'axios';
import { serverAddress as proxy } from '../custom-modules/customModules';

import defPfp from '../Assets/general/def-pfp.png';

function SmartPfp({ id, username, size, loading = true }) 
{
  // console.log(image);

  const [isLoaded, setIsLoaded] = useState(false);
  const [imageId, setImageId] = useState(undefined);

    if (!isLoaded || !loading)
    {
      if (id !== undefined)
      {
        axios.post(`${proxy}/getPfpById`, { id: id })
          .then((res) => 
          {
            setImageId(res.data);
            setIsLoaded(true);
          })
          .catch((err) => console.error(err));
      }
      else if (id === undefined && username !== undefined)
      {
        axios.post(`${proxy}/getPfpByUsername`, { username: username })
          .then((res) => 
          {
            setImageId(res.data);
            setIsLoaded(true);
          })
          .catch((err) => console.error(err));
      }
      else setIsLoaded(true);



    }

  return (
    <span className="pfp-image-container" style={(size == undefined) ? { backgroundImage: `url(${(imageId == undefined || imageId == null || imageId == "") ? defPfp : `${proxy}/src/${imageId}`})` } : { backgroundImage: `url(${(imageId == undefined || imageId == null || imageId == "") ? defPfp : `${proxy}/src/${imageId}`})`, height: size, width: size }}></span>
  );
}

export default SmartPfp;