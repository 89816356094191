//// IMPORTING LIBRARIES

// the React library
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import { serverAddress as proxy, filterRepeats, parseSQLArrayProperties, dateToString, convertDate } from '../custom-modules/customModules';
import Loading from '../customComponents/Loading';

// Custom Modules
import { arrayShares, removeIndex, removeIndexByValue, arrayWith, arrayInherits, arrayEquals, sliceStringByValue, numberOfNumbers } from '../custom-modules/customModules';

// redux related
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../redux/actionCreators';

import { countries } from '../custom-modules/countries';

// stylesheets
import './styles/globalStyles.scss';
import './styles/home.scss';

// importing the Post component
import Post from '../customComponents/Post';

import panelSmallIcon from './../Assets/general/small-panel.png';
import panelLargeIcon from './../Assets/general/large-panel.png';

import optionsIcon from './../Assets/general/options.png';

import magClouds from '../Assets/general/magnifing-cloud.png';


import Image from '../Assets/0y1pvct3kzz21.png';
import sigaret from '../Assets/reaction.mp4';
import startWarsClip from '../Assets/VADER_._DC_Shared_post_with_mando.mp4';
import aughhh from '../Assets/sources/aughhhhh.mp4';
import chadMusic from '../Assets/sources/chad_music.mp4';
import cyberHeadset from '../Assets/sources/cyberpunkHeadset.jpg';
import cyberMoney from '../Assets/sources/cyberpunkMoney.jpg';
import gtaScene from '../Assets/sources/gtaScene.png';
import ripRainbowPlayer from '../Assets/sources/rainbowSixPlayer.jpg';
import sigaretoMix from '../Assets/sources/reactionMix.mp4';
import ripTechno from '../Assets/sources/riptechno.mp4';


//// THE MAIN FUNCTION
function Home() 
{

  const [posts, setPosts] = useState([{}]);
  const [fetched, setFetched] = useState(false);
  const [loadedPosts, setLoadedPosts] = useState([]);
  const [userInfo, setUserInfo] = useState(null);

  const [panelSize, setPanelSize] = useState(1);
  const [nationality, setNationality] = useState("international");

  const currentDate = new Date();

  useEffect(() => 
  {
    console.log(localStorage.getItem("windowMode"));
  }, [localStorage.getItem("windowMode")]);

  useEffect(() => 
  {
    if (localStorage.getItem("panel-size") != null) setPanelSize(Number(localStorage.getItem("panel-size")));
  }, []);

  // useEffect(() => 
  // {
  //   if (localStorage.getItem("coins-remain") != null && Number(localStorage.getItem("coins-remain")) != 0)
  //   {
  //     axios.post(`${proxy}/addCoins`, {  })
  //   }
  // }, []);


  if (fetched == false)
  {

        axios.post(`${proxy}/getPosts`)
        .then((res) => 
        {
          let data = res.data;
          if (nationality != "international")
          {
            let newDat = [];
            // let newIndex = 0;

            for (let i = 0; i < data.length; i++)
            {
              if (data[i].nationality == nationality)
              {
                if (newDat.length == 0) newDat = [data[i]];
                else newDat = [...newDat, data[i]];
              }
            }

            data = newDat;
          }

          let allPosts = [];
          for (let i = 0; i < data.length; i += 1)
          {

              allPosts = [...allPosts, {
                user: "",
                user_id: data[i].user_id,
                post_id: data[i].post_id,
                title: data[i].title,
                description: data[i].description,
                // source: require(`./../uploads/${data[i].src}`),
                source: `${proxy}/src/${data[i].src}`,
                like: data[i].likes,
                dislike: data[i].dislikes,
                boosts: data[i].boosts,
                type: data[i].type,
                tags: JSON.parse(data[i].tags),
                status: data[i].status,
                date_of_creation: data[i].date_of_creation
              }];
          }

          let users = [];
          data.map((value, index) => users[index] = value.user_id);
          users = filterRepeats(users);
          
          axios.post(`${proxy}/getUsernames`, { users: users })
              .then((res) => 
              {
                let usernames = [];
                
                for (let j = 0; j < res.data.length; j += 1)
                {
                  (usernames.length == 0) ?
                    usernames = [{ id: res.data[j].user_id, username: res.data[j].username }]
                    :
                    usernames = [...usernames, { id: res.data[j].user_id, username: res.data[j].username }];
                }

                for (let x = 0; x < allPosts.length; x += 1)
                {
                  for (let y = 0; y < usernames.length; y += 1)
                  {
                    if (allPosts[x].user_id == usernames[y].id) allPosts[x].user = usernames[y].username;
                  }
                }


                
                
                if (localStorage.getItem("user") != "" && localStorage.getItem("user") != null)
                {
                  axios.post(`${proxy}/getUserById`, { id: localStorage.getItem("user") })
                  .then((res) => 
                  {
                    setLoadedPosts(allPosts.reverse());
                    setUserInfo(parseSQLArrayProperties(res.data[0]));
                    setFetched(true);
                  })
                  .catch((err) => console.error(err));
                }
                else 
                {
                  setLoadedPosts(allPosts.reverse());
                  setFetched(true);
                }
              });
          
          // console.log(allPosts);
        })
        .catch((err) => console.error(err));
  }

  // console.log(posts);

  class DescriptionPost
  {
    constructor (user, title, description, source, like, dislike, boosts, type, tags, status)
    {
      this.user = user;
      this.title = title;
      this.description = description;
      this.source = source;
      this.like = like;
      this.dislike = dislike;
      this.boosts = boosts;
      this.type = type;
      this.tags = tags;
      this.status = status;
    }
  }

  // console.log(loadedPosts)



  const [plusImage, setPlusImage] = useState("unhover");
  const [superSecretSetting, setSuperSecretSetting] = useState("");

  const reduxState =  useSelector((state) => state);
  const dispatch = useDispatch();


  const { setCurrentPage } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => 
  {
    setCurrentPage("home");
  }, []);

  // in this part, we will link values to the "reduxStore" objects, destrcturing the object into variables to use them
  let coins = reduxState.coins;
 



  // let AllPosts = [
  //   new DescriptionPost("Erfan", "VamPsdfsdfsdfars", "In yaru vampardfgdfgse!", require('../Assets/0y1pvct3kzz21.png'), 2, 5, 0, "img", ["#deltarune"], "Verified"),
  //   new DescriptionPost("Erfan", "SIGARETO BEDE BE MAN!", "haha! funnnyyyyyyyyyyyyyyyyyy!", require('../Assets/reaction.mp4'), 21, 3, 3, "vid", ["#fun", "#irani"]),
  //   new DescriptionPost("Daniyal", "Star Wars cool clip!", "You'll get goosbumps!", require('../Assets/VADER_._DC_Shared_post_with_mando.mp4'), 24, 1, 0, "vid", ["#great", "#goosbumps", "#wow"], "Verified"),
  //   new DescriptionPost("Arta", "The aughhhh", "Too late to post this since it spread everywhere! But here it is!", require('../Assets/sources/aughhhhh.mp4'), 24, 1, 0, "vid", ["#aughhhMeme"]),
  //   new DescriptionPost("Daniyal", "heh +18", "Gush kon!", require('../Assets/sources/chad_music.mp4'), 24, 1, 0, "vid", ["#irani"]),
  //   new DescriptionPost("Erfan", "CyberPunk Meme #27", "Bruuuuuuh", require('../Assets/sources/cyberpunkHeadset.jpg'), 24, 1, 0, "img", ["#cyberPunkMemes", "#aughhhMeme"]),
  //   new DescriptionPost("Daniyal", "FUTURE", "Funnnnnnnn", require('../Assets/sources/cyberpunkMoney.jpg'), 24, 1, 0, "img", ["#cyberPunkMemes", "#aughhhMeme"], "Verified"),
  //   new DescriptionPost("Erfan", "Niceeee", "Dash man kheyli richam!", require('../Assets/sources/gtaScene.png'), 24, 1, 0, "img", ["#cyberPunkMemes", "#aughhhMeme"]),
  //   new DescriptionPost("Alireza", "RIP", "Yeki az behtarin va telented tarin player haye Rainbow june khodesho tavvasote tasadof az dast dad!", require('../Assets/sources/rainbowSixPlayer.jpg'), 24, 1, 0, "img", ["#rainbow", "#rip"]),
  //   new DescriptionPost("Erfan", "MIXE SIGARETO", "SIGARETO BEDE BE MAN", require('../Assets/sources/reactionMix.mp4'), 24, 1, 0, "vid", ["#irani"], "Removed"),
  //   new DescriptionPost("Arta", "rip TEXHNOBLADE", "Rest in peace!", require('../Assets/sources/riptechno.mp4'), 24, 1, 0, "vid", ["#riptechno"], "Deleted")
  // ];

  // localStorage.setItem("test1Post", JSON.stringify(AllPosts));

  // function setAddPostImg()
  // {
  //   if (plusImage == "unhover")
  //   {
  //     return UnclickedAddPostImg;
  //   }
  //   else if (plusImage == "hover")
  //   {
  //     return ClickedAddPostImg;
  //   }
  //   else
  //   {
  //     console.log(`Error! Cannot detect if the user hovered over the "addPost" button or not!`);
  //     return;
  //   }
  // }

  function handleClickAddPlus()
  {
    setPlusImage("hover");
  }

  function hanldeUnclickAddPlus()
  {
    setPlusImage("unhover");
  }

  function coinCounterModifier()
  {
    if (coins <= 0)
    {
      return { color: "red" };
    }
    else if (coins > 0)
    {
      return { color: "black" };
    }
  }

  function changeFilter(filter, value)
  {

    console.log(value);
    switch (filter)
    {
      case "panel-size":
        {
          setPanelSize(value);
          localStorage.setItem("panel-size", value);
        }
        break;

      case "nat":
        {
          setNationality(value);
          setFetched(false);
        }
        break;
    }
  }

  // console.log(window.innerWidth);

  return (

    <>
        <nav className="filter-container">
          <strong style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img src={optionsIcon} style={{ width: "22px", marginRight: "5px" }} /> Filter: </strong>
          <div>
            <select className="dropdown" style={{ padding: "3px" }} value={nationality} onInput={(e) => changeFilter("nat", e.target.value)}>
              <option value={"international"} selected>International</option>
                {
                  countries.map((value, index) => <option value={value.code}>{value.name}</option>)
                }
                    
            </select>
            <div className="option-selector">
              <button className={(panelSize === 1) ? "option-selector-selected" : ""} onClick={() => changeFilter("panel-size", 1)}><img src={panelLargeIcon} style={{ width: "25px" }} /></button>
              <button className={(panelSize === 2) ? "option-selector-selected" : ""} onClick={() => changeFilter("panel-size", 2)}><img src={panelSmallIcon} style={{ width: "25px" }} /></button>
            </div>
          </div>
        </nav>
        <br></br>
        
      

       <div className="posts" style={(panelSize !== 2) ? {} : { display: "flex", justifyContent: "center", alignItems: "flex-start", flexWrap: "wrap" }}>
        {(!fetched) ? 
          <Loading />
          : 
          (loadedPosts.length == 0) ?
            <div className="content-page"><img src={magClouds} style={{ maxWidth: "200px" }} /><h3>No memes were found with your specified filter!</h3></div>
            :
            loadedPosts.map((value, index) => 
              <Post descriptionObject={value} userInfo={userInfo} style={(panelSize !== 2) ? {} : { margin: "5px", width: "570px"  }} />
        )}
       </div>
    </>
  );
}

export default Home;