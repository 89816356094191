import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../pages/styles/globalStyles.scss';
import './styles/post.scss';

import { useSelector, useDispatch } from 'react-redux';
import { actionCreators } from '../redux/actionCreators';
import { bindActionCreators } from 'redux';

import axios from 'axios';
import { serverAddress as proxy, parseSQLArrayProperties, arrayIncludes, removeIndexByValue, dateToString } from '../custom-modules/customModules';

import redTrashCan from '../Assets/post/trashcan-red.png';

// like and dislike
import clickedLikeBtn from '../Assets/post/clicked-like.png';
import unclickedLikeBtn from '../Assets/post/uclicked-like.png';
import unclickedDislikeBtn from '../Assets/post/unclicked-dislike.png';

import playBtn from '../Assets/post/video/playBtn.png';
import pauseBtn from '../Assets/post/video/pauseBtn.png';

import volumeHigh from '../Assets/post/video/volumeHighBtn.png';
import volumeLow from '../Assets/post/video/volumeLowBtn.png';
import volumeMute from '../Assets/post/video/volumeMuteBtn.png';

import Pfp from './Pfp';


function RenderVideo({ source })
{
  // DOM useRefs
  const videoElement = useRef();
  const videoContentElement = useRef();
  const timelineElement = useRef();

  // useStates
  const [pause, setPause] = useState(true);
  const [muted, setMute] = useState(false);
  const [volume, setVolume] = useState(1);
  const [previousVolume, setPreviousVolume] = useState(1);
  const [currentDuration, setCurrentDuration] = useState("0:00");
  const [totalDuration, setTotalDuration] = useState("0:00");

  // Not so useful useStates
  const [rawCurrentDuration, setRawCurrentDuration] = useState(0);
  const [rawTotalDuration, setRawTotalDuration] = useState(0);

  // Timeline Handling

  let wasPaused;
  let isScrubbing;
  function toggleScrubbing(e)
  {
    const rect = timelineElement.current.getBoundingClientRect();
    const percentage = Math.min(Math.max(0, e.x - rect.x), rect.width) / rect.width;

    isScrubbing = (e.buttons & 1) === 1;
    videoContentElement.current.classList.toggle("scrubbing", isScrubbing);

    if (isScrubbing == true)
    {
      setPause(true);
      wasPaused = pause;
    }
    else
    {
      videoElement.current.currentTime = percentage * videoElement.current.duration;
      if (!wasPaused) setPause(false);
    }

    handleTimelineUpdate(e);
  }

  function handleTimelineUpdate(e)
  {
    const rect = timelineElement.current.getBoundingClientRect();
    const percentage = Math.min(Math.max(0, e.x - rect.x), rect.width) / rect.width;

    timelineElement.current.style.setProperty("--preview-line", percentage);


    if (isScrubbing == true)
    {
      e.preventDefault();
      timelineElement.current.style.setProperty("--progress-line", percentage);
    }
  }

  document.addEventListener("mouseup" , (e) => 
  {
    if (isScrubbing == true) toggleScrubbing(e);
  });

  document.addEventListener("mousemove", (e) => 
  {
    if (isScrubbing) handleTimelineUpdate(e);
  });


  // Duration Control
  const zeroFromatter = new Intl.NumberFormat(undefined, {
    minimumIntegerDigits: 2
  });

  function modifyTime(value)
  {
    let seconds = Math.floor((value) % 60);
    let minutes = Math.floor(((value) / 60) % 60);
    let hours = Math.floor((value) / 3600);

    if (hours == 0)
    {
      return `${minutes}:${zeroFromatter.format(seconds)}`;
    }
    else if (hours >= 1)
    {
      return `${hours}:${minutes}:${zeroFromatter.format(seconds)}`;
    }
    else
    {
      console.log(`Error!: Cannot setup the total time duration text!`);
    }
  }

  function setupTotalDuration(value, func, rawValue, rawFunc)
  {
    func(value);
    rawFunc(rawValue);
  }

  function setupCurrentDuration(value, func, rawValue, rawFunc)
  {
    func(value);
    rawFunc(rawValue);
    const percent = videoElement.current.currentTime / videoElement.current.duration;
    timelineElement.current.style.setProperty("--progress-line", percent);
  }

  useEffect(() => 
  {
    if (rawCurrentDuration >= rawTotalDuration)
    {
      setPause(true);
    }

  }, [rawCurrentDuration]);
  
  // Volume Control
  useEffect(() => 
  {
    videoElement.current.volume = volume;
    videoElement.current.muted = muted;

    if (volume == 0)
    {
      setMute(true);
    }
    else if ((volume > 0))
    {
      setMute(false);
    }
    else 
    {
      console.log(`Error!: Cannot specify the mute property!`);
    }

  }, [volume, muted]);



  function toggleMute()
  {
    if (muted == true)
    {
      renderPreviousVolumeByButton();
    }
    else if (muted == false)
    {
      muteByButton();
    }
    else
    {
      console.log(`Error!: Cannot set the muted property!`);
    }
  }

  function muteByButton()
  {
    setPreviousVolume(volume);
    setVolume(0);
  }

  function renderPreviousVolumeByButton()
  {
    setVolume(previousVolume);
  }

  function setVolumeIcon()
  {
    if ((muted == true))
    {
      return volumeMute;
    }
    else if (volume >= .5)
    {
      return volumeHigh;
    }
    else if (volume < .5)
    {
      
      return volumeLow;
    }
    else
    {
      console.log(`Error!: Cannot set the Icon of the volume button!`);
      return;
    }
  }


  //////////////////////////////////////

  // Pausing

  function specifySource()
  {
    if (pause == true)
    {
      return playBtn;
    }
    else if (pause == false)
    {
      return pauseBtn;
    }
    else
    {
      console.log(`Error!: Could not specify the source!`);
      return;
    }
  }

  useEffect(() => 
  {
    if (pause == true)
    {
      setToPause();
    }
    else if (pause == false)
    {
      setToPlay();
    }


    function setToPause()
    {
      videoElement.current.pause();
      videoContentElement.current.classList.add("paused");
    }

    function setToPlay()
    {
      videoElement.current.play();
      videoContentElement.current.classList.remove("paused");
    }
  }, [pause]);

  function togglePlayPause()
  {
    pause ? setPause(false) : setPause(true);
  }



  return (
    <div ref={videoContentElement} className="videoContainerCls paused">
        <div className="videoControlsContainerCls">
          <div className="videoTimelineCls" ref={timelineElement} onMouseMove={(e) => handleTimelineUpdate(e.nativeEvent)} onMouseDown={(e) => toggleScrubbing(e.nativeEvent)}>
            <div className="timeLineCls">
              <div className="thumbIndicatorCls"></div>
            </div>
          </div>
          <div className="videoControlsCls">
            <button className="videoPlayPauseCls" onClick={togglePlayPause}>
              <img src={specifySource()}></img>
            </button>
            <div className="volumeContainerCls">
              <button className="muteBtnCls" onClick={toggleMute}>
                <img src={setVolumeIcon()}></img>
              </button>
              <input className="videoVolumeSlider" type="range" min="0" max="1" step="any" value={volume} onChange={(e) => setVolume(e.target.value)}></input>
            </div>
            <div className="videoDurationContainerCls">
              <div className="currentTimeCls">{currentDuration}</div>
              /
              <div className="totalTimeCls">{totalDuration}</div>
            </div>
          </div>
        </div>
        <video ref={videoElement} src={source} onLoadedData={(e) => setupTotalDuration(modifyTime(e.target.duration), setTotalDuration, e.target.duration, setRawTotalDuration)} onTimeUpdate={(e) => setupCurrentDuration(modifyTime(e.target.currentTime), setCurrentDuration, e.target.currentTime, setRawCurrentDuration)}></video>
      </div>
  );
}

function ImageVideoRender({ type, source })
{
  if (type == "image")
  {
    return (
      <div className="image-container">
        <img src={source} ></img>
      </div>
    );
  }
  else if (type == "video")
  {
    return (
      <RenderVideo source={source} />
    );
  }
  else
  {
    console.log(`Error!: Cannot detect the type of the source!`);
    return;
  }
}




// function Post({ descriptionObject, customizedMargin, type, showFollowButton, userInfo, style }) 
// {
//   const reduxState =  useSelector((state) => state);


//   var coins;
//   const dispatch = useDispatch();


//   const { setCoins, incrementCoins, decrementCoins, setCurrentPage, setSearch } = bindActionCreators(actionCreators, dispatch);

//   const [liked, setLiked] = useState(false);
//   const [boosted, setBoosted] = useState(false);
//   const [loaded, setLoaded] = useState(false);
//   const [likes, setLikes] = useState(descriptionObject.like);
//   const [followed, setFollowed] = useState(false);
//   const [roles, setRoles] = useState([]);
//   const [deleted, setDeleted] = useState(false);
//   const [verified, setVerified] = useState(false);
//   const [pfpAddress, setPfpAddress] = useState(undefined);
//   const [banned, setBanned] = useState(undefined);

//   const currentDate = new Date();

//   const redirect = useNavigate();

//   // console.log(userInfo);

//   if (userInfo !== null && loaded == false)
//   {
//     // console.log(userInfo, "sadsadasda")
//     let user = userInfo;
//     // coins = user.coins;
//     let likedPosts = user.liked_posts;
//     let userRoles = user.roles;
//     setRoles(userRoles);
//     setPfpAddress(user.pfp);
        
//     for (let i = 0; i < likedPosts.length; i += 1)
//     {
//       if (likedPosts[i] == descriptionObject.post_id) 
//       {
//         setLiked(true);
//       }
//     }

//     let boostedPosts = user.boosted_posts;

//     // console.log("a");
//     for (let j = 0; j < boostedPosts.length; j += 1)
//     {
//       if (boostedPosts[j] == descriptionObject.post_id) 
//       {
//         setBoosted(true);
//       }
//     }

//     for (let x = 0; x < user.followed.length; x += 1)
//     {
//       if (user.followed[x] == descriptionObject.user_id) setFollowed(true);
//     }
//     setLoaded(true);
//   }
//   else if (loaded == false) setLoaded(true);

//   if (pfpAddress === undefined)
//   {
//     axios.post(`${proxy}/getPfpById`, { id: descriptionObject.user_id })
//       .then((res) => 
//       {
//         setPfpAddress(res.data);
//       })
//       .catch((err) => console.error(err));
//   }

//   if (banned === undefined)
//   {
//     axios.post(`${proxy}/banCheck`, { id: descriptionObject.user_id })
//       .then((res) => 
//       {
//         if (res.data != undefined) setBanned(res.data);
//         else setBanned(false);
//       })
//       .catch((err) => console.error(err));
//   }

//   // if (localStorage.getItem("user") != "" && localStorage.getItem("user") != null)
//   // {
//   //   axios.post(`${proxy}/getUserById`, { id: localStorage.getItem("user") })
//   //     .then((res) => 
//   //     {
        

        
//   //     })
//   //     .catch((err) => console.error(err));
//   // }

//   // console.log(coins);


//   function like()
//   {
//     if (localStorage.getItem("user") == "" || localStorage.getItem("user") == null)
//     {
//       redirect("/signin");
//       return;
//     }
//     axios.post(`${proxy}/getUserById`, { id: localStorage.getItem("user") })
//       .then((res) => 
//       {
//         let likedPosts = parseSQLArrayProperties(res.data[0]);
//         likedPosts = likedPosts.liked_posts;

//         console.log(likedPosts, arrayIncludes(likedPosts, descriptionObject.post_id));

//         if (arrayIncludes(likedPosts, descriptionObject.post_id))
//         {
//           if (likes == 0) return;
//           console.log("c");
//           likedPosts = removeIndexByValue(likedPosts, descriptionObject.post_id);
//           console.log(likedPosts);
//           axios.post(`${proxy}/unlikePost`, { id: descriptionObject.post_id })
//             .then(() => 
//             {
//               axios.post(`${proxy}/modifyUser`, { id: localStorage.getItem("user"), action: "modifyLikes", property: JSON.stringify(likedPosts) })
//                 .then(() => 
//                 {
//                   setLiked(false);
//                   setLikes(likes - 1);
//                 })
//                 .catch((err) => console.error(err));

//             })
//             .catch((err) => console.error(err));
//         }
//         else
//         {
//           likedPosts = [...likedPosts, descriptionObject.post_id];
//           axios.post(`${proxy}/likePost`, { id: descriptionObject.post_id })
//             .then(() => 
//             {
//               axios.post(`${proxy}/modifyUser`, { id: localStorage.getItem("user"), action: "modifyLikes", property: JSON.stringify(likedPosts) })
//                 .then(() => 
//                 {
//                   setLiked(true);
//                   setLikes(likes + 1);
//                 })
//                 .catch((err) => console.error(err));
//             })
//             .catch((err) => console.error(err))
//         }
//       })
//       .catch((err) => console.error(err));
//   }

//   function follow()
//   {
//     if (localStorage.getItem("user") == "" || localStorage.getItem("user") == null)
//     {
//       redirect("/signin");
//       return;
//     }

//     axios.post(`${proxy}/getUserById`, { id: localStorage.getItem("user") })
//       .then((res) => 
//       {
//         let data = parseSQLArrayProperties(res.data[0]);
//         let follows = data.followed;

//         if (arrayIncludes(follows, descriptionObject.user_id))
//         {
//           (follows.length == 1) ?
//             follows = []
//             :
//             follows = removeIndexByValue(follows, descriptionObject.user_id);

//             axios.post(`${proxy}/unfollow`, { id: descriptionObject.user_id })
//               .then((res) => 
//               {
//                 axios.post(`${proxy}/modifyUser`, { id: localStorage.getItem("user"), action: "modifyFollowed", property: JSON.stringify(follows) })
//                 .then((res) => 
//                 {
//                     setFollowed(false);
//                     console.log(res.data);
//                   })
//                   .catch((err) => console.error(err));
//               })
//               .catch((err) => console.error(err));

//         }
//         else
//         {
//           (follows.length == 0) ?
//             follows = [descriptionObject.user_id]
//             :
//             follows = [...follows, descriptionObject.user_id];

//             axios.post(`${proxy}/follow`, { id: descriptionObject.user_id })
//               .then((res) => 
//               {
//                 axios.post(`${proxy}/modifyUser`, { id: localStorage.getItem("user"), action: "modifyFollowed", property: JSON.stringify(follows) })
//                 .then((res) => 
//                 {
//                     setFollowed(true);
//                     console.log(res.data);
//                   })
//                   .catch((err) => console.error(err));
//               })
//               .catch((err) => console.error(err));
//         }
//       })
//       .catch((err) => console.error(err));
//   }

//   function boost()
//   {
//     return;
//     if (localStorage.getItem("user") == "" || localStorage.getItem("user") == null)
//     {
//       redirect("/signin");
//       return;
//     }

//     if (boosted != false) return;
    

//     console.log("triggered!");

//     axios.post(`${proxy}/getCoins`, { id: localStorage.getItem("user") })
//       .then((res) => 
//       {
//         coins = Number(res.data[0]);
//         if (coins < 5) return;
//         axios.post(`${proxy}/removeCoins`, { id: localStorage.getItem("user"), coins: 5 })
//         .then((res) => 
//         {
//           axios.post(`${proxy}/addCoins`, { id: descriptionObject.user_id, coins: 5 })
//             .then((res) => 
//             {
//               axios.post(`${proxy}/getUserById`, { id: localStorage.getItem("user") })
//                 .then((res) => 
//                 {
//                   let data = parseSQLArrayProperties(res.data[0]);
//                   let boosted = data.boosted_posts;
//                   console.log(data);

//                   (boosted.length == 0) ?
//                     boosted = [descriptionObject.post_id]
//                     :
//                     boosted = [...boosted, descriptionObject.post_id];
                    

//                   axios.post(`${proxy}/modifyUser`, { id: localStorage.getItem("user"), action: "modifyBoosteds", property: JSON.stringify(boosted) })
//                     .then((res) => 
//                     {
//                       console.log(boosted);
//                       console.log(res.data);
//                       setBoosted(true);
//                       coins -= 5;
//                     })
//                     .catch((err) => console.error(err));
//                 })
//                 .catch((err) => console.error(err));
//             })
//             .catch((err) => console.error(err));
//         })
//         .catch((err) => console.error(err));
//       })
//       .catch((err) => console.error(err));
//   }

//   function deletePost()
//   {
//     axios.post(`${proxy}/modifyPost`, { id: descriptionObject.post_id, action: "remove" })
//       .then((res) => 
//       {
//         console.log(res.data);
//         setDeleted(true);
//       })
//       .catch((err) => console.error(err));
//   }

//   function verifyPost()
//   {
//     axios.post(`${proxy}/modifyPost`, { id: descriptionObject.post_id, action: "verify" })
//       .then((res) => 
//       {
//         console.log(res.data);
//         setVerified(true);
//       })
//       .catch((err) => console.error(err));
//   }

//   function setBoostButton()
//   {

//     if (coins > 5)
//     {
//       return "boostBtnCls";
//     }
//     else 
//     {
//       return "unclickedBoostBtnCls";
//     }
//   }



//   const userDirection = useNavigate();

//   // console.log(dateToString(`${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}-${currentDate.getHours()}:${currentDate.getMinutes()}`, "24/8/2023-16:00"));

//   if (banned === true) return(<></>);

//   return (
//     <div className="postCls panel" style={customizedMargin == "" ? { ...style } : { ...style, marginLeft: customizedMargin, marginRight: customizedMargin }}>
//         <div className="userDivCls">
//           <span style={{ display: "flex", flexWrap: "wrap" }}>
//             <Link to={`/account/${descriptionObject.user_id}`}><Pfp image={pfpAddress} size="40px" /></Link>
//             <button style={{ display: "flex", flexWrap: "wrap", cursor: "pointer", background: "none", border: "none", fontSize: "16px" }} onClick={() => userDirection(`/account/${descriptionObject.user_id}`)}>{descriptionObject.user}</button>
//             <button className="newpost-follow-btn" style={(descriptionObject.user_id == localStorage.getItem("user") || showFollowButton === false) ? { display: "none" } : {}} onClick={follow}>{(followed == false) ? "FOLLOW" : "UNFOLLOW"}</button>
//             {((arrayIncludes(roles, "mod") || arrayIncludes(roles, "admin")) && deleted !== true) ? <button className="del-btn"><img src={redTrashCan} style={{ width: "20px" }} onClick={deletePost} /></button> : <></>}
//             {((arrayIncludes(roles, "mod") || arrayIncludes(roles, "admin")) && deleted !== true && descriptionObject.status !== "verified" && verified !== true) ? <button className="post-verify-btn" onClick={verifyPost}>Verify</button> : (arrayIncludes(roles, "mod") || arrayIncludes(roles, "admin")) ? <span style={{ color: "green", fontWeight: "bold" }}>Verified!</span> : <></>}
//             <span style={(deleted === true) ? { color: "red", fontWeight: "bold" } : { display: "none" }}>POST DELETED!</span>
//           </span>
//           <span style={{ display: "flex", flexWrap: "wrap" }}>
//             {(descriptionObject.tags == undefined) ? <span></span> : (descriptionObject.tags.length !== 0) ? descriptionObject.tags.map((value, index) => <span className="newpost-hashtag">{value}</span>)
//             :
//             <span></span>
//             }
//           </span>
//         </div>
        
//          <br></br>

//         <div className="imagePlaceHolderCls">
//           <ImageVideoRender type={descriptionObject.type} source={descriptionObject.source} />
//         </div> <br></br> <br></br>
        
//         <div className="authorInfoCls">
//           {(descriptionObject.title == "" || descriptionObject.title.replaceAll(" ", "") == "") ? <></> : <><label className="titleCls">{descriptionObject.title}</label> <br></br></>}
//           {
//             (descriptionObject.description == "" || descriptionObject.description.replaceAll(" ", "") == "") ?
//               <></>
//               :
//               <p className="descriptionCls">
//                 <label>Description:</label> <br></br>
//                 {descriptionObject.description}
//               </p>
//           }
//         </div>
//         {(descriptionObject.title == "" && descriptionObject.title.replaceAll(" ", "") == "" && descriptionObject.description == "" && descriptionObject.description.replaceAll(" ", "") == "") ? <></> : <hr></hr>}
//         <div className="optionsBarCls" style={(type == "prototype") ? { display: "none" } : {  }}>
//           <div className="likeDislikeCls">
//               <button onClick={like}><img src={(liked == true) ? clickedLikeBtn : unclickedLikeBtn}></img></button>
//               <label className="likeCountCls">{likes}</label>
//           </div>
//           <span style={{ color: "gray" }}>
//           {
//             (descriptionObject.date_of_creation == null || descriptionObject.date_of_creation == "-") ?
//             descriptionObject.date_of_creation
//             :
//             dateToString(`${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}-${currentDate.getHours()}:${currentDate.getMinutes()}`, descriptionObject.date_of_creation)
//           }
//           </span>

//           {/* <div className="boostDateCls"> */}
//             {/* <label style={{ fontWeight: "bold" }}>{descriptionObject.boosts} BOOSTS</label> */}
//             {/* <button className="unclickedBoostBtnCls">BOOST</button> */}
//             {/* <button className={(coins < 5) ? "unclickedBoostBtnCls" : (boosted == false) ? "boostBtnCls" : "unclickedBoostBtnCls" } onClick={boost}>{(boosted != false) ? "BOOSTED" : "BOOST"}</button> */}
//           {/* </div> */}
//           <></>
//         </div>

//     </div>
//   );
// }

function Post({ descriptionObject, customizedMargin, type, showFollowButton, userInfo, style }) 
{
  const reduxState =  useSelector((state) => state);


  var coins;
  const dispatch = useDispatch();


  const { setCoins, incrementCoins, decrementCoins, setCurrentPage, setSearch } = bindActionCreators(actionCreators, dispatch);

  const [liked, setLiked] = useState(false);
  const [boosted, setBoosted] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [likes, setLikes] = useState(descriptionObject.like);
  const [followed, setFollowed] = useState(false);
  const [roles, setRoles] = useState([]);
  const [deleted, setDeleted] = useState(false);
  const [verified, setVerified] = useState(false);
  const [pfpAddress, setPfpAddress] = useState(undefined);
  const [banned, setBanned] = useState(undefined);

  const currentDate = new Date();

  const redirect = useNavigate();

  // console.log(userInfo);

  if (userInfo !== null && loaded == false)
  {
    // console.log(userInfo, "sadsadasda")
    let user = userInfo;
    // coins = user.coins;
    let likedPosts = user.liked_posts;
    let userRoles = user.roles;
    setRoles(userRoles);
    setPfpAddress(user.pfp);
        
    for (let i = 0; i < likedPosts.length; i += 1)
    {
      if (likedPosts[i] == descriptionObject.post_id) 
      {
        setLiked(true);
      }
    }

    let boostedPosts = user.boosted_posts;

    // console.log("a");
    for (let j = 0; j < boostedPosts.length; j += 1)
    {
      if (boostedPosts[j] == descriptionObject.post_id) 
      {
        setBoosted(true);
      }
    }

    for (let x = 0; x < user.followed.length; x += 1)
    {
      if (user.followed[x] == descriptionObject.user_id) setFollowed(true);
    }
    setLoaded(true);
  }
  else if (loaded == false) setLoaded(true);

  if (pfpAddress === undefined)
  {
    axios.post(`${proxy}/getPfpById`, { id: descriptionObject.user_id })
      .then((res) => 
      {
        setPfpAddress(res.data);
      })
      .catch((err) => console.error(err));
  }

  if (banned === undefined)
  {
    axios.post(`${proxy}/banCheck`, { id: descriptionObject.user_id })
      .then((res) => 
      {
        if (res.data != undefined) setBanned(res.data);
        else setBanned(false);
      })
      .catch((err) => console.error(err));
  }

  // if (localStorage.getItem("user") != "" && localStorage.getItem("user") != null)
  // {
  //   axios.post(`${proxy}/getUserById`, { id: localStorage.getItem("user") })
  //     .then((res) => 
  //     {
        

        
  //     })
  //     .catch((err) => console.error(err));
  // }

  // console.log(coins);


  function like()
  {
    if (localStorage.getItem("user") == "" || localStorage.getItem("user") == null)
    {
      redirect("/signin");
      return;
    }
    axios.post(`${proxy}/getUserById`, { id: localStorage.getItem("user") })
      .then((res) => 
      {
        let likedPosts = parseSQLArrayProperties(res.data[0]);
        likedPosts = likedPosts.liked_posts;

        console.log(likedPosts, arrayIncludes(likedPosts, descriptionObject.post_id));

        if (arrayIncludes(likedPosts, descriptionObject.post_id))
        {
          if (likes == 0) return;
          console.log("c");
          likedPosts = removeIndexByValue(likedPosts, descriptionObject.post_id);
          console.log(likedPosts);
          axios.post(`${proxy}/unlikePost`, { id: descriptionObject.post_id })
            .then(() => 
            {
              axios.post(`${proxy}/modifyUser`, { id: localStorage.getItem("user"), action: "modifyLikes", property: JSON.stringify(likedPosts) })
                .then(() => 
                {
                  setLiked(false);
                  setLikes(likes - 1);
                })
                .catch((err) => console.error(err));

            })
            .catch((err) => console.error(err));
        }
        else
        {
          likedPosts = [...likedPosts, descriptionObject.post_id];
          axios.post(`${proxy}/likePost`, { id: descriptionObject.post_id })
            .then(() => 
            {
              axios.post(`${proxy}/modifyUser`, { id: localStorage.getItem("user"), action: "modifyLikes", property: JSON.stringify(likedPosts) })
                .then(() => 
                {
                  setLiked(true);
                  setLikes(likes + 1);
                })
                .catch((err) => console.error(err));
            })
            .catch((err) => console.error(err))
        }
      })
      .catch((err) => console.error(err));
  }

  function follow()
  {
    if (localStorage.getItem("user") == "" || localStorage.getItem("user") == null)
    {
      redirect("/signin");
      return;
    }

    axios.post(`${proxy}/getUserById`, { id: localStorage.getItem("user") })
      .then((res) => 
      {
        let data = parseSQLArrayProperties(res.data[0]);
        let follows = data.followed;

        if (arrayIncludes(follows, descriptionObject.user_id))
        {
          (follows.length == 1) ?
            follows = []
            :
            follows = removeIndexByValue(follows, descriptionObject.user_id);

            axios.post(`${proxy}/unfollow`, { id: descriptionObject.user_id })
              .then((res) => 
              {
                axios.post(`${proxy}/modifyUser`, { id: localStorage.getItem("user"), action: "modifyFollowed", property: JSON.stringify(follows) })
                .then((res) => 
                {
                    setFollowed(false);
                    console.log(res.data);
                  })
                  .catch((err) => console.error(err));
              })
              .catch((err) => console.error(err));

        }
        else
        {
          (follows.length == 0) ?
            follows = [descriptionObject.user_id]
            :
            follows = [...follows, descriptionObject.user_id];

            axios.post(`${proxy}/follow`, { id: descriptionObject.user_id })
              .then((res) => 
              {
                axios.post(`${proxy}/modifyUser`, { id: localStorage.getItem("user"), action: "modifyFollowed", property: JSON.stringify(follows) })
                .then((res) => 
                {
                    setFollowed(true);
                    console.log(res.data);
                  })
                  .catch((err) => console.error(err));
              })
              .catch((err) => console.error(err));
        }
      })
      .catch((err) => console.error(err));
  }

  function boost()
  {
    return;
    if (localStorage.getItem("user") == "" || localStorage.getItem("user") == null)
    {
      redirect("/signin");
      return;
    }

    if (boosted != false) return;
    

    console.log("triggered!");

    axios.post(`${proxy}/getCoins`, { id: localStorage.getItem("user") })
      .then((res) => 
      {
        coins = Number(res.data[0]);
        if (coins < 5) return;
        axios.post(`${proxy}/removeCoins`, { id: localStorage.getItem("user"), coins: 5 })
        .then((res) => 
        {
          axios.post(`${proxy}/addCoins`, { id: descriptionObject.user_id, coins: 5 })
            .then((res) => 
            {
              axios.post(`${proxy}/getUserById`, { id: localStorage.getItem("user") })
                .then((res) => 
                {
                  let data = parseSQLArrayProperties(res.data[0]);
                  let boosted = data.boosted_posts;
                  console.log(data);

                  (boosted.length == 0) ?
                    boosted = [descriptionObject.post_id]
                    :
                    boosted = [...boosted, descriptionObject.post_id];
                    

                  axios.post(`${proxy}/modifyUser`, { id: localStorage.getItem("user"), action: "modifyBoosteds", property: JSON.stringify(boosted) })
                    .then((res) => 
                    {
                      console.log(boosted);
                      console.log(res.data);
                      setBoosted(true);
                      coins -= 5;
                    })
                    .catch((err) => console.error(err));
                })
                .catch((err) => console.error(err));
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  }

  function deletePost()
  {
    axios.post(`${proxy}/modifyPost`, { id: descriptionObject.post_id, action: "remove" })
      .then((res) => 
      {
        console.log(res.data);
        setDeleted(true);
      })
      .catch((err) => console.error(err));
  }

  function verifyPost()
  {
    axios.post(`${proxy}/modifyPost`, { id: descriptionObject.post_id, action: "verify" })
      .then((res) => 
      {
        console.log(res.data);
        setVerified(true);
      })
      .catch((err) => console.error(err));
  }

  function setBoostButton()
  {

    if (coins > 5)
    {
      return "boostBtnCls";
    }
    else 
    {
      return "unclickedBoostBtnCls";
    }
  }



  const userDirection = useNavigate();

  // console.log(dateToString(`${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}-${currentDate.getHours()}:${currentDate.getMinutes()}`, "24/8/2023-16:00"));

  if (banned === true) return(<></>);

  return (
    <div className="postCls panel" style={customizedMargin == "" ? { ...style } : { ...style, marginLeft: customizedMargin, marginRight: customizedMargin }}>
        <div className="userDivCls">
          <span style={{ display: "flex", flexWrap: "wrap" }}>
            <Link to={`/account/${descriptionObject.user_id}`}><Pfp image={pfpAddress} size="40px" /></Link>
            <button style={{ display: "flex", flexWrap: "wrap", cursor: "pointer", background: "none", border: "none", fontSize: "16px" }} onClick={() => userDirection(`/account/${descriptionObject.user_id}`)}>{descriptionObject.user}</button>
            <button className="newpost-follow-btn" style={(descriptionObject.user_id == localStorage.getItem("user") || showFollowButton === false) ? { display: "none" } : {}} onClick={follow}>{(followed == false) ? "FOLLOW" : "UNFOLLOW"}</button>
            {((arrayIncludes(roles, "mod") || arrayIncludes(roles, "admin")) && deleted !== true) ? <button className="del-btn"><img src={redTrashCan} style={{ width: "20px" }} onClick={deletePost} /></button> : <></>}
            {((arrayIncludes(roles, "mod") || arrayIncludes(roles, "admin")) && deleted !== true && descriptionObject.status !== "verified" && verified !== true) ? <button className="post-verify-btn" onClick={verifyPost}>Verify</button> : (arrayIncludes(roles, "mod") || arrayIncludes(roles, "admin")) ? <span style={{ color: "green", fontWeight: "bold" }}>Verified!</span> : <></>}
            <span style={(deleted === true) ? { color: "red", fontWeight: "bold" } : { display: "none" }}>POST DELETED!</span>
          </span>
          <span style={{ display: "flex", flexWrap: "wrap" }}>
            {(descriptionObject.tags == undefined) ? <span></span> : (descriptionObject.tags.length !== 0) ? descriptionObject.tags.map((value, index) => <span className="newpost-hashtag">{value}</span>)
            :
            <span></span>
            }
          </span>
        </div>
        
         <br></br>

        <div className="imagePlaceHolderCls">
          <ImageVideoRender type={descriptionObject.type} source={descriptionObject.source} />
        </div> <br></br> <br></br>
        
        <div className="authorInfoCls">
          {(descriptionObject.title == "" || descriptionObject.title.replaceAll(" ", "") == "") ? <></> : <><label className="titleCls">{descriptionObject.title}</label> <br></br></>}
          {
            (descriptionObject.description == "" || descriptionObject.description.replaceAll(" ", "") == "") ?
              <></>
              :
              <p className="descriptionCls">
                <label>Description:</label> <br></br>
                {descriptionObject.description}
              </p>
          }
        </div>
        {(descriptionObject.title == "" && descriptionObject.title.replaceAll(" ", "") == "" && descriptionObject.description == "" && descriptionObject.description.replaceAll(" ", "") == "") ? <></> : <hr></hr>}
        <div className="optionsBarCls" style={(type == "prototype") ? { display: "none" } : {  }}>
          <div className="likeDislikeCls">
              <button onClick={like}><img src={(liked == true) ? clickedLikeBtn : unclickedLikeBtn}></img></button>
              <label className="likeCountCls">{likes}</label>
          </div>
          <span style={{ color: "gray" }}>
          {
            (descriptionObject.date_of_creation == null || descriptionObject.date_of_creation == "-") ?
            descriptionObject.date_of_creation
            :
            dateToString(`${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}-${currentDate.getHours()}:${currentDate.getMinutes()}`, descriptionObject.date_of_creation)
          }
          </span>

          {/* <div className="boostDateCls"> */}
            {/* <label style={{ fontWeight: "bold" }}>{descriptionObject.boosts} BOOSTS</label> */}
            {/* <button className="unclickedBoostBtnCls">BOOST</button> */}
            {/* <button className={(coins < 5) ? "unclickedBoostBtnCls" : (boosted == false) ? "boostBtnCls" : "unclickedBoostBtnCls" } onClick={boost}>{(boosted != false) ? "BOOSTED" : "BOOST"}</button> */}
          {/* </div> */}
          <></>
        </div>

    </div>
  );
}


export default Post;
