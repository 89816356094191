import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './styles/notifications.scss';

import { serverAddress as proxy, removeIndex, removeIndexByValue, arrayIncludes } from '../custom-modules/customModules';

import SmartPfp from '../customComponents/SmartPfp';

import redTrashCan from '../Assets/post/trashcan-red.png';
import noNotifs from '../Assets/general/asleep-bell.png';

import Loading from '../customComponents/Loading';
import loadingGif from '../Assets/general/loader.gif';

// Assets
import notifIcon from '../Assets/notifs.png';
import { copyFileSync } from 'fs';
import axios from 'axios';


function Notifications() 
{
    const [isLoaded, setIsLoaded] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [notificationDeletes, setNotificationDeletes] = useState([]); 

    const redirect = useNavigate();

    if (!isLoaded)
    {
        if (localStorage.getItem("user") != "" && localStorage.getItem("user") != null)
        {
            axios.post(`${proxy}/getNotifications`, { id: localStorage.getItem("user") })
                .then((res) => 
                {
                    let data = res.data;

                    setNotifications(data.reverse());
                    setIsLoaded(true);
                })
                .catch((err) => console.error(err));
        }
        else
        {
            redirect("/signin");
        }
    }

    function deleteNotification(message_id, index)
    {
        // for (let i = 0; i < notifications.length; i++)
        // {
        //     if (notifications[i].message_id == message_id)
        //     {
        //         let newArr = removeIndex(notifications, i);
        //         setNotifications(newArr);

        //         break;
        //     }
        // }

        setNotificationDeletes([...notificationDeletes, index]);
        console.log(message_id)

        axios.post(`${proxy}/notificationHide`, { id: message_id })
            .then((res) => 
            {
                let newArr2 = removeIndexByValue(notificationDeletes, index);
                setNotificationDeletes(newArr2);

                let newArr = removeIndex(notifications, index);
                setNotifications(newArr);
            })
            .catch((err) => console.error(err));
    }


    if (!isLoaded) return <Loading />;

  return (
    <div className="message-container">
        <div style={{ textAlign: "left", margin: "10px" }}>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <img src={notifIcon} style={{ height: "40px", margin: "2px" }} />
                <strong style={{ fontSize: "40px" }}>Notifications</strong>
            </div> 
            <span style={{ color: "gray", fontSize: "12px" }}>If you get any notifications, it will apear here!</span> <br />
            <hr />
        </div> <br />
        <div style={{ margin: "10px" }}>

        {
            (notifications.length == 0) ? 
            <div className="content-page">
                <img src={noNotifs} style={{ maxWidth: "200px" }} />
                <h3 style={{ fontSize: "30px" }}>No Notifications.</h3>
            </div>
                :
                notifications.map((value, index) => 
                <div className="panel2">
                    <div className="message-header">
                        <div className="message-header-left">
                            {/* <Link to={`/account/${value.from_id}`}> */}
                                <SmartPfp id={value.from_id} size="25px" />
                                <strong>{value.from_username}</strong>
                            {/* </Link> */}
                        </div>
                        <div className="message-header-right">
                            <span style={{ color: "gray" }}>{value.date_of_creation}</span>
                        </div>
                    </div>
                    <hr  /> <br />
                    <strong style={{ fontSize: "18px" }}>{value.subject}</strong> <br />
                    <p className="message-content-render">{value.content}</p> 
                    <br /> <br />
                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        {
                            (arrayIncludes(notificationDeletes, index)) ?
                                <img src={loadingGif} style={{ width: "50px" }} />
                                :
                                <button className="del-btn" onClick={() => deleteNotification(value.message_id, index)}><img src={redTrashCan} style={{ width: "20px" }} /></button>
                        }
                    </div>
                </div>)
        }


            
        </div>
    </div>
  );
}

export default Notifications;