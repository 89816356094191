
function reducer(state = "home", action)
{
    switch (action.type)
    {
        case "changePage":
            return action.payload.currentPage;

        default:
            return state;
    }
}

export default reducer;