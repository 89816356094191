import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Post from '../customComponents/Post';
import "./styles/uploadPost.scss";
import LoadingBar from '../customComponents/LoadingBar';
import TipDidUKnow from '../customComponents/TipDidUKnow';


function UploadPost() 
{
    const [upload, setUpload] = useState(true);
    const [uploaded, setUploaded] = useState(true);
    const [failed, setFailed] = useState({failed: false, reason: "Network connection was lost! Make sure that your connection is secured, then try again."});

    const allPosts = JSON.parse(localStorage.getItem("test1Post"));

    console.log(Math.floor(Math.random() * 2) + 1);

  return (
    <div style={{ minHeight: "100vh" }}>
        <br />
        {
            (failed.failed == false) ?
                (upload === false) ?
                <div className="newpost-container">
                    <div className="newpost">
                        <span className="uploadpost-title">Ready to upload?</span> <br /> <br />
                        <p><strong>Quick Note!</strong><br/>Your post has to be eligable to stay in Memedify. This means that the meme you've uploaded should be under the Memedify and ORDIOUS Terms of Service. If not, moderators can remove your post.</p> <br /><br /><br />
                        <Post descriptionObject={allPosts[0]} type="prototype" />
                        <button className="btn" style={{ padding: "15px 30px 15px 30px", margin: "5px" }}>Post</button>
                        <Link to="/createpost"><button className="btn" style={{ padding: "15px 30px 15px 30px", margin: "5px", backgroundColor: "tomato" }}>Do not Post</button></Link>
                    </div>
                </div>
                :
                    (uploaded == false) ?
                    <div>
                    <div className="newpost-container">
                            <div className="newpost">
                                <span className="uploadpost-title">Your Meme is going to be uploaded...</span> <br /> <br />
                                <p>This might take a while depeneding on your network connection. Go grab a cup of coffee and wait until your meme to upload.</p>

                                <LoadingBar /> <br />
                                <TipDidUKnow />
                                <button className="btn" style={{ padding: "15px 30px 15px 30px", margin: "5px" }}>Cancel</button>
                            </div> 
                        </div>
                    </div>
                    :
                    <div className="newpost-container">
                        <div className="newpost">
                            <span className="uploadpost-title" style={{ color: "green" }}>Your meme was successfully uploaded!</span> <br /> <br />
                            <p>Wait a day or so, for your post to gain likes and boosts. Your post will also be reviewed by a Moderator in 24-48 hrs. If it was not eligable it would be removed.</p> <br /> <br />
                            <button className="btn" style={{ padding: "15px 30px 15px 30px", margin: "5px" }}>Home</button>
                            <button className="btn" style={{ padding: "15px 30px 15px 30px", margin: "5px" }}>Dashboard</button>
                        </div>
                    </div>
                :
                <div className="newpost-container">
                    <div className="newpost">
                        <span className="uploadpost-title" style={{ color: "tomato" }}>Oops! Something went wrong!</span> <br /> <br />
                        <p>{failed.reason}</p> <br /> <br />
                        <button className="btn" style={{ padding: "15px 30px 15px 30px", margin: "5px" }}>Home</button>
                        <button className="btn" style={{ padding: "15px 30px 15px 30px", margin: "5px" }}>Dashboard</button>
                    </div>
                </div>
        }
    </div>
  );
}

export default UploadPost
