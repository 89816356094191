

function reducer(state = "", action)
{
    switch (action.type)
    {
        case "setSearch":
            return action.payload.searchString;

        default:
            return state;
    }
}

export default reducer;