import React, { useState, useEffect } from 'react';
import "./styles/dashboard.scss";
import { useNavigate } from 'react-router-dom';

import axios from "axios";
import { serverAddress as proxy, parseSQLArrayProperties, filterText, arrayIncludes, removeIndex, removeIndexByValue, formatBytes, specifyPosts, numberOfCharactersInString, convertDate } from "../custom-modules/customModules";
import Loading from '../customComponents/Loading';
import LoadingSmall from '../customComponents/LoadingSmall';

import redTrashCan from '../Assets/post/trashcan-red.png';
import returnIcon from "../Assets/general/return-arrow.png";

import Condition from '../customComponents/Condition';

import { v4 as uuid } from 'uuid';

import notFoundIcon from '../Assets/general/maginifing-notfound.png';
import greenCheck from '../Assets/general/green-check.png';
import greenCheck2 from '../Assets/general/green-check-2.png';
import redX from '../Assets/general/red-x.png';

import Pfp from '../customComponents/Pfp';
import SmartPfp from '../customComponents/SmartPfp';

import loadingGif from '../Assets/general/loader.gif';


// import infoWhite from '../Assets/general/info-white';
// import infoBlack from '../Assets/general/info-black';


function RenderDetails({ option, style })
{
    option = option.toLowerCase();

    const [aboutMe, setAboutMe] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [infoCharacterLimits, setInfoCharacterLimits] = useState({ display_name: 15, about_me: 300 });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [loadedPosts, setLoadedPosts] = useState([]);
    const [roleUsers, setRoleUsers] = useState([]);
    const [submitVisible, setSubmitVisible] = useState(false);

    const [appliedMods, setAppliedMods] = useState([]);
    const [oneUseCodes, setOneUseCodes] = useState([]);
    const [allOneUseCodesExpired, setAllOneUseCodesExpired] = useState(false);
    const [bannedUsers, setBannedUsers] = useState([]);

    const [pfpSize, setPfpSize] = useState({ pfp: 0, max: 2097152 });
    const [pfpError, setPfpError] = useState("");

    const [pfpLoad, setPfpLoad] = useState("false");
    const [unbanLoad, setUnbanLoad] = useState(false);

    const [banAppeals, setBanAppeals] = useState([]);

    const redirect = useNavigate();



    if (loaded == false && localStorage.getItem("user") != "" && localStorage.getItem("user") != null)
    {
        axios.post(`${proxy}/getUserById`, { id: localStorage.getItem("user") })
            .then((res) => 
            {
                let userData = parseSQLArrayProperties(res.data[0]);
                axios.post(`${proxy}/getPostsByUserId`, { id: localStorage.getItem("user"), filter: "none" })
                    .then((res) => 
                    {
                        let data = res.data.reverse();
                        let allPosts = [];
                        for (let i = 0; i < data.length; i += 1)
                        {
                            allPosts = [...allPosts, {
                            user: "",
                            user_id: data[i].user_id,
                            post_id: data[i].post_id,
                            title: data[i].title,
                            description: data[i].description,
                            source: `${proxy}/src/${data[i].src}`,
                            like: data[i].likes,
                            dislike: data[i].dislikes,
                            boosts: data[i].boosts,
                            type: data[i].type,
                            tags: JSON.parse(data[i].tags),
                            status: data[i].status
                            }];

                        }
                        setLoadedPosts(allPosts);
                        setUserInfo(userData);
                        axios.post(`${proxy}/getRoles`)
                            .then((res) => 
                            {
                                let roleUsersTemp = [];
                                for (let j = 0; j < res.data.length; j += 1)
                                {
                                    let acc = parseSQLArrayProperties(res.data[j]);
                                    (roleUsersTemp.length == 0) ?
                                        roleUsersTemp = [{ id: acc.user_id, username: acc.username, roles: acc.roles }]
                                        :
                                        roleUsersTemp = [...roleUsersTemp, { id: acc.user_id, username: acc.username, roles: acc.roles }];
                                }
                                axios.post(`${proxy}/getOneUse`, { id: localStorage.getItem("user") })
                                    .then((res) => 
                                    {
                                        if (res.data.length == 0) setOneUseCodes(null);
                                        else
                                        {
                                            let codes = res.data[0];
                                            codes = [codes.code1, codes.code2, codes.code3, codes.code4, codes.code5, codes.code6, codes.code7, codes.code8];
                                            let allExpired = true;
                                            for (let i = 0; i < 8; i += 1)
                                            {
                                                if (codes[i].charAt(0) != "e" && codes[i].charAt(1) != "-")
                                                {
                                                    allExpired = false;
                                                    break;
                                                }
                                            }
                                            setAllOneUseCodesExpired(allExpired);
                                            setOneUseCodes(codes);
                                        }
                                        axios.post(`${proxy}/getAppliedMods`)
                                            .then((res) => 
                                            {
                                                let applied = res.data;
                                                setAppliedMods(applied);
                                                setRoleUsers(roleUsersTemp);
                                                axios.post(`${proxy}/getBannedUsers`)
                                                .then((res) => 
                                                    {
                                                        setBannedUsers(res.data);
                                                        axios.post(`${proxy}/getBanAppeals`, { mod_id: localStorage.getItem("user") })
                                                            .then((res) => 
                                                            {
                                                                setBanAppeals(res.data);
                                                                setLoaded(true);
                                                            })
                                                            .catch((err) => console.error(err));
                                                    })
                                                    .catch((err) => console.error(err));
                                            })
                                            .catch((err) => console.error(err));
                                    })
                                    .catch((err) => console.error(err));
                            })
                            .catch((err) => console.error(err));
                    })
                    .catch((err) => console.error(err));
            })
            .catch((err) => console.error(err));
    }

    function submitUserInfo()
    {
        setIsSubmitting(true);
        axios.post(`${proxy}/modifyUser`, { id: localStorage.getItem("user"), action: "modifyUserInfo", property: userInfo })
            .then((res) => 
            {
                setSubmitVisible(true);
                setIsSubmitting(false);
            })
            .catch((err) => console.error(err));
    }

    if (submitVisible === true)
    {
        setTimeout(() => 
        {
            setSubmitVisible(false);
        }, 10000)
    }

    function clearText(txt, type)
    {
        // if (filterText(txt, ["`", "'", '"']) === false) return;

        switch (type)
        {
            case "name":
                if (txt.toString().length > infoCharacterLimits.display_name) return userInfo.display_name;
                break;

            case "about":
                {
                    if (txt.toString().length > infoCharacterLimits.about_me) return userInfo.profile_desc;
                    else if (numberOfCharactersInString(txt, "\n") > 6) return userInfo.profile_desc;
                }
                break;
        }
        return filterText(txt, ["`", "'", '"']);
    }

    function modifyPosts(statement, id)
    {
        switch (statement)
        {
            case "delete":
                {
                    axios.post(`${proxy}/modifyPost`, { id: id, action: "delete" })
                        .then((res) => 
                        {
                            console.log(res.data);
                            setLoaded(false);
                        })
                        .catch((err) => console.error(err));
                }
                break;

                case "return":
                    {
                        axios.post(`${proxy}/modifyPost`, { id: id, action: "return" })
                            .then((res) => 
                            {
                                console.log(res.data);
                                setLoaded(false);
                            })
                            .catch((err) => console.error(err));
                    }
                    break;

            default: 
                return;
        }
    }

    function removeRole(id, role)
    {
        let roles = [];
        let allRoles = roleUsers;
        let userExists = false;

        for (let i = 0; i < allRoles.length; i += 1)
        {
            if (allRoles[i].id == id)
            {
                roles = allRoles[i].roles;
                roles = removeIndexByValue(roles, role);
                allRoles[i].roles = roles;
                if (roles.length == 0) allRoles = removeIndex(allRoles, i);
                userExists = true;

                break;
            }
        }
        
        
        if (userExists !== true) return;
        
        axios.post(`${proxy}/modifyUser`, { id: id, action: "modifyRoles", property: JSON.stringify(roles) })
            .then((res) => 
            {
                setRoleUsers(allRoles);
                setLoaded(false);
            })
            .catch((err) => console.error(err));
    }

    function generateOneUseCodes()
    {
        axios.post(`${proxy}/insertOneUse`, { id: localStorage.getItem("user") })
            .then((res) => 
            {
                setLoaded(false);
                // console.log(res.data);
            })
            .catch((err) => console.error(err));
    }

    function resetOneUseCodes()
    {
        axios.post(`${proxy}/resetOneUse`, { id: localStorage.getItem("user") })
            .then((res) => 
            {
                setLoaded(false);
                // console.log(res.data);
            })
            .catch((err) => console.error(err));
    }

    function actOnAppliedMods(act, id)
    {
        if (act == "a")
        {
            axios.post(`${proxy}/acceptMod`, { id: id })
                .then((res) => 
                {
                    axios.post(`${proxy}/getUserById`, { id: id })
                        .then((res) => 
                        {
                            let dat = parseSQLArrayProperties(res.data[0]);
                            let datRoles = dat.roles;
                            
                            (datRoles.length == 0) ?
                                datRoles = ["mod"]
                                :
                                datRoles = [...datRoles, "mod"];
                            
                            axios.post(`${proxy}/modifyUser`, { action: "modifyRoles", property: JSON.stringify(datRoles), id: id })
                                .then((res) => 
                                {
                                    setLoaded(false);
                                    console.log(res.data);
                                })
                                .catch((err) => console.error(err));
                        })  
                        .catch((err) => console.error(err));
                })
                .catch((err) => console.error(err));
        }
        else 
        {
            axios.post(`${proxy}/rejectMod`, { id: id })
            .then((res) => 
            {
                setLoaded(false);
            })
            .catch((err) => console.error(err));
        }
    }

    function setPfp(e)
    {
        setPfpError("");
        let file = e.target.files[0];
        
        let eligable = true;

        // console.log(file);

        if ((file.type == "image/jpeg" || file.type == "image/jpg" || file.type == "image/png") == false) 
        {
            eligable = false;
            setPfpError("The uploaded file type is not supported!");
        }
        if (file.size > pfpSize.max)
        {
            eligable = false;
            setPfpError("The uploaded file is bigger than the accepted file size!")
        }

        setPfpSize({ ...pfpSize, pfp: file.size });

        if (eligable == false)
        {
            file = undefined;
            return;
        }

        setPfpLoad("loading");

        const formData = new FormData();
        formData.append("pfp-img", file);

        axios.post(`${proxy}/uploadPfp`, formData)
            .then((res) => 
            {
                let pfpAddress = res.data;
                axios.post(`${proxy}/modifyUser`, { id: localStorage.getItem("user"), action: "modifyPfp", property: pfpAddress })
                    .then((res) => 
                    {
                        setPfpLoad(false);
                        setLoaded(false);
                    })
                    .catch((err) => console.error(err));
            })
            .catch((err) => console.error(err));

    }

    function unban(id)
    {
        setUnbanLoad(true);
        axios.post(`${proxy}/setBanStatus`, { id: id, status: "allowed" })
            .then((res) => 
            {
                setUnbanLoad(false);
                setLoaded(false);
            }) 
            .catch((err) => console.error(err));
    }

    //// messaging
    const [msgUsername, setMsgUsername] = useState("");
    const [msgSubject, setMsgSubject] = useState("");
    const [msgContent, setMsgContent] = useState("");
    const [msgError, setMsgError] = useState("");
    const [isMsgSending, setIsMsgSending] = useState(false);
    const [isMsgSent, setIsMsgSent] = useState(false);

    const msgCharacterLimits = {
        username: 30,
        subject: 50,
        content: 500
    };

    let bannedCharacters = ["`", "'"];

    function setupField(value, field)
    {
      if (filterText(value, bannedCharacters) !== false)
      {
        switch (field)
        {
            case "usr":
                if (value.length <= msgCharacterLimits.username) setMsgUsername(filterText(value, ["#", "/", "*", "$", "^", "&", "(", ")", "+", "-", "!", "~", "`", " "]).toLowerCase());
                break;
            
            case "sub":
                if (value.length <= msgCharacterLimits.subject) setMsgSubject(filterText(value, bannedCharacters));
                break;

            case "con":
                if (value.length <= msgCharacterLimits.content) setMsgContent(filterText(value, bannedCharacters));
                break;
        }
      }
    }

    function sendMessage()
    {
        const date = new Date();

        setMsgError("");
        setIsMsgSending(true);
        let error = "";

        axios.post(`${proxy}/sendMsg`, { from_id: localStorage.getItem("user"), to_username: msgUsername, date_of_creation: convertDate(`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`), subject: msgSubject, content: msgContent })
            .then((res) => 
            {
                if (res.data == "err - none") error = "There is no user with the provided username.";
                else if (res.data == "err - more") error = "Something went wrong in the databse!";
                else if (res.data == "err") error = "Something went wrong!";
                else if (res.data == "success")
                {
                    setIsMsgSent(true);
                    setMsgUsername("");
                    setMsgSubject("");
                    setMsgContent("");
                }
                else error = "Error!: There was no defined response from the backend.";

                setIsMsgSending(false);
                setMsgError(error);
            })
            .catch((err) => 
            {
                console.error(err);
                setMsgError("Something Went Wrong!");
                setIsMsgSending(false);
            });
    }

    function banAppeal(user_id, ban_id)
    {
      localStorage.setItem("ban-appeal-id", user_id.toString());
      localStorage.setItem("ban-appeal-ban-id", ban_id.toString());
      redirect("/ban-appeal")
    }
  

    if (loaded != true)
    {
        return (<Loading />);
    }
    else if (option == "user settings")
    {
        return (
        <div style={{ textAlign: "left", ...style }}>
            <span className="dashboard-section-title">ABOUT ME</span>
            <div className="field">
                <textarea className="textarea-field" placeholder="Say something about yourself!..." onInput={(e) => setUserInfo({ ...userInfo, profile_desc: clearText(e.target.value, "about") })} value={userInfo.profile_desc}></textarea>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", margin: "5px" }}><span style={{ color: "gray" }}>{userInfo.profile_desc.toString().length} / {infoCharacterLimits.about_me}</span></div> <br />
            <span className="dashboard-section-title">DISPLAY NAME</span>
            <div className="field">
                <input type="text" className="input-field" value={userInfo.display_name} onInput={(e) => setUserInfo({ ...userInfo, display_name: clearText(e.target.value, "name") })} />
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", margin: "5px" }}><span style={{ color: "gray" }}>{userInfo.display_name.toString().length} / {infoCharacterLimits.display_name}</span></div><br />
            {/* <span className="dashboard-section-title">USERNAME</span>
            <div className="field">
                <input type="text" className="input-field" />
            </div>
            <span style={{ color: "gray", fontSize: "12px" }}>You can only change your username twice!</span>
            <br /> <br /> */}
            <span className="dashboard-section-title">PROFILE PICTURE</span>
            <div className="field" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px" }}>
                <div>
                    <Pfp image={userInfo.pfp} />
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "column" }}>
                    <span style={{ fontSize: "14px", color: "gray" }}>Please Upload an image that is less than 2MB. <br />It can be .png, .jpg</span>
                    <input type="file" id="pfp-upload-catcher" accept="image/png, image/jpeg, image/jpg" style={{ display: "none" }} onChange={setPfp} />
                    <label for="pfp-upload-catcher" className="btn" style={{ padding: "10px 20px 10px 20px" }}>Upload Profile Picture...</label>
                </div>
            </div> 
            <div className="newpost-properties">
                <span className="newpost-error-label">{pfpError}</span>
                <div style={{ display: "flex", alignItems: "center" }}>{(pfpLoad == "loading") ? <><LoadingSmall /><label>{formatBytes(pfpSize.pfp)} / {formatBytes(pfpSize.max)}</label></> : <label>{formatBytes(pfpSize.pfp)} / {formatBytes(pfpSize.max)}</label>}</div>
            </div>
            <br />
            <span className="dashboard-section-title">ONE-USE CODES</span> <br />
            <span style={{ fontSize: "12px", color: "gray" }}>One-Use codes will be used where passwords cannot be used. For security reasons or other reasons, you cannot identify yourself by entering your password. These codes will be used once and expire after. If you use the all of the codes, you can reset them. <strong>DO NOT SHARE THEM ANYWHERE WITH ANYONE!</strong></span> <br />
            <div style={{ textAlign: "left" }}>
                {
                    (oneUseCodes === null) ? (pfpLoad == "loading") ? <button className='btn' style={{ padding: "16px 30px 16px 30px", opacity: 0.5 }}>Enable One-Use Codes</button> : <button className='btn' style={{ padding: "16px 30px 16px 30px" }} onClick={generateOneUseCodes}>Enable One-Use Codes</button>
                    : 
                    <ul>
                        {
                            oneUseCodes.map((value, index) => <li style={(value.charAt(0) == "e" && value.charAt(1) == "-") ? { color: "red" } : {}}>{(value.charAt(0) == "e" && value.charAt(1) == "-") ? `${value} (Expired)` : value}</li>)
                        }
                    </ul>
                }
                <br />
                {(allOneUseCodesExpired === true) ? <button className='btn' style={{ padding: "16px 30px 16px 30px" }} onClick={resetOneUseCodes}>Reset Codes</button> : <></>}
            </div>
        
            <br /><br /><br />
            {
                (isSubmitting) ?
                    <img src={loadingGif} style={{ width: "50px" }} />
                    :
                    (submitVisible) ?
                        <button className="btn-deactivated" style={{ padding: "20px 40px 20px 40px" }}>Submit</button>
                        :
                        <button className="btn" style={{ padding: "20px 40px 20px 40px" }} onClick={submitUserInfo}>Submit</button>
            } <br /> <br />     
            <div style={(submitVisible) ? { opacity: "1" } : { opacity: "0" }} className="dashboard-submit">
                <img src={greenCheck2} style={{ height: "30px", margin: "4px" }} />
                <span style={{ color: "rgb(0,186,0)", fontWeight: "bold" }}>Information successfully submitted!</span>
            </div> <br />

        </div>
        );
    }
    else if (option == "analytics")
    {
        return (
            <div style={style}>
                {/* <Condition title="Are you sure you want to Delete this post?" hasInput={false} buttonsObjects={[{ title: "Yes", btnType: "deny" }, { title: "No", btnType: "allow" }]} status={true} /> */}
                {
                    (loadedPosts.length == 0) ?
                    <div className="content-page"><img src={notFoundIcon} style={{ maxWidth: "200px" }} /><h3>You never Posted.</h3></div>
                        :
                        loadedPosts.map((value, index) => 
                        <div className="dashboard-post-analytics">
                            <div className="dashboard-post-analytics-left">
                                <span style={{ fontWeight: "bold" }}>{value.title}</span>
                                <strong style={{ marginLeft: "5px", marginRight: "5px" }}><span style={(value.status == "verified") ? { color: "green" } : (value.status == "removed") ? { color: "red" } : (value.status == "deleted") ? { color: "tomato" } : { color: "gray" }}>{(value.status != undefined) ? value.status.charAt(0).toUpperCase() + value.status.slice(1, value.status.length) : "Unverified"}</span></strong>
                                {value.tags.map((value, index) => <span className="newpost-hashtag">{value}</span>)}
                            </div>
                            <div className="dashboard-post-analytics-right">
                                {/* <span style={(value.like - value.dislike >= 20) ? { color: "green" } : (value.like - value.dislike < 20 && value.like - value.dislike > 0 ) ? { color: "rgb(189, 189, 57)" } : { color: "tomato" }}>{value.like} / {value.dislike} (ratio: {value.like - value.dislike})</span> */}
                                <span style={{ color: "gray", fontWeight: "bold" }}>BOOSTS: {value.boosts}</span>
                                {(value.status == "deleted") ?
                                    <button className="del-btn"><img src={returnIcon} style={{ width: "20px" }} onClick={() => modifyPosts("return", value.post_id)} /></button>
                                :
                                    (value.status == "removed") ?
                                        <span style={{ color: "red", fontWeight: "bold" }}>Post removed!</span>
                                    :
                                        <button className="del-btn"><img src={redTrashCan} style={{ width: "20px" }} onClick={() => modifyPosts("delete", value.post_id)} /></button>}
                            </div>
                        </div>)
                }
            </div>
        );
    }
    else if (option == "moderators")
    {
        return (
            <div style={style}>
                {
                    (roleUsers.length == 0) ?
                    <div className="content-page"><img src={notFoundIcon} style={{ maxWidth: "200px" }} /><h3>Nobody has any roles.</h3></div>
                        :
                        roleUsers.map((value, index) =>    
                            <div className="list-item">
                                <div className="list-item-left">{value.username}</div>
                                <div className="list-item-right">
                                    {(arrayIncludes(value.roles, "mod")) ? <button className="del-btn" onClick={() => removeRole(value.id, "mod")}>Remove Moderation</button> : <></>}
                                    {(arrayIncludes(value.roles, "admin")) ? <button className="del-btn" style={{ color: "tomato" }} onClick={() => removeRole(value.id, "admin")}>Remove Administration</button> : <></>}
                                </div>
                            </div>)
                }
            </div>
        );
    }
    else if (option == "moderator acceptance")
    {
        return (
            <div style={style}>
                {
                    (appliedMods.length == 0) ?
                    <div className="content-page"><img src={notFoundIcon} style={{ maxWidth: "200px" }} /><h3>No one has applied yet.</h3></div>
                    :
                    appliedMods.map((value, index) =>    
                        <div className="list-item" >
                            <div className="list-item-left">
                                {value.username} | <span style={{ color: "gray" }}> <strong>Discord ID: </strong>{value.discord_id}</span>
                            </div>
                            <div className="list-item-right">
                                <button className="del-btn" onClick={() => actOnAppliedMods("a", value.user_id)}><img src={greenCheck} style={{ width: "25px" }} /></button>
                                <button className="del-btn" onClick={() => actOnAppliedMods("r", value.user_id)}><img src={redX} style={{ width: "25px" }} /></button>
                            </div>
                        </div>)
                }
            </div>
        );
    }
    else if (option == "bans")
    {
        return (
            <div style={style}>
                {
                    (unbanLoad) ?
                        <LoadingSmall size="50px" />
                        :
                        <></>
                }
                {
                    (bannedUsers.length == 0) ?
                    <div className="content-page"><img src={notFoundIcon} style={{ maxWidth: "200px" }} /><h3>No one is currently banned.</h3></div>
                    :
                    bannedUsers.map((value, index) =>    
                        <div className="list-item" >
                            <div className="list-item-left">
                                {value.username} | <span style={{ color: "gray" }}> <strong>Ban ID: </strong>{value.ban_id}</span>
                            </div>
                            {/* <div className="list-item-right">
                                {
                                    (!unbanLoad) ?
                                       <button className="del-btn" onClick={() => unban(value.user_id)}>Un-Ban</button>
                                       :
                                       <button className="del-btn" style={{ opacity: 0.5 }}>Un-Ban</button>
                                }
                            </div> */}
                        </div>)
                }
            </div>
        );
    }
    else if (option == "ban appeals")
    {
        return (
            <div style={style}>
                {
                    (unbanLoad) ?
                        <LoadingSmall size="50px" />
                        :
                        <></>
                }
                {
                    (banAppeals.length == 0) ?
                        <div className="content-page"><img src={notFoundIcon} style={{ maxWidth: "200px" }} /><h3>No one is currently requesting you.</h3></div>
                        :
                        banAppeals.map((value, index) => 
                        <div className="list-item">
                            <div className="list-item-left" style={{ display: "block", textAlign: "left" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div>
                                        <span>Username: </span>
                                        <strong>{value.username}</strong>
                                    </div>
                                    <button className="del-btn" onClick={() => banAppeal(value.user_id, value.ban_id)}>Review</button>
                                </div>
                                 <br /> <br />
                                <span className="dashboard-section-title">BAN REASON</span> <br />
                                <span style={{ whiteSpace: "pre-line" }}>{value.reason}</span><br /> <br />
                                <span className="dashboard-section-title">APPEAL DESCRIPTION</span><br />
                                <span style={{ whiteSpace: "pre-line" }}>{value.appeal_desc}</span>
                            </div>
                            <div className="list-item-right">
                                
                            </div>
                        </div>)
                }
            </div>
        );
    }
    else if (option == "send message")
    {
        if (isMsgSent) return (
            <div style={style}>
                <div className="panel">
                    <strong>Your Message was successfully sent!</strong> <br /> <br />
                    <button className="btn" style={{ padding: "15px 30px 15px 30px" }} onClick={() => setIsMsgSent(false)}>Return</button>
                </div>
            </div>
        );


        return (
            <div style={style} className="dashboard-message-container">
                <div className="dashboard-message-section">
                    <strong>To: </strong> 
                    <div className="field dashboard-message-field">
                        <SmartPfp username={msgUsername} size="30px" loading={false} />
                        <input type="text" className="input-field" placeholder="Username" spellCheck={false} value={msgUsername} onInput={(e) => setupField(e.target.value, "usr")} />
                    </div>
                </div>
                    <div className="dashboard-message-footer"><span>{msgUsername.toString().length} / {msgCharacterLimits.username}</span></div>
                <div className="dashboard-message-section">
                    <strong>Subject:</strong>
                    <div className="field dashboard-message-field">
                        <input type="text" className="input-field" value={msgSubject} onInput={(e) => setupField(e.target.value, "sub")} />
                    </div>
                </div> 
                    <div className="dashboard-message-footer"><span>{msgSubject.toString().length} / {msgCharacterLimits.subject}</span></div>
                    <br /> <br />
                <div style={{ textAlign: "left" }}>
                    <strong>Content:</strong>
                </div>
                <div className="field">
                    <textarea className="textarea-field" value={msgContent} onInput={(e) => setupField(e.target.value, "con")} />
                </div> 
                <div className="dashboard-message-footer"><span>{msgContent.toString().length} / {msgCharacterLimits.content}</span></div><br /> 
                <span style={{ color: "red", fontSize: "16px" }}>{msgError}</span> <br /> <br />
                {
                    (!isMsgSending) ?
                    <button className="btn" style={{ padding: "15px 30px 15px 30px" }} onClick={sendMessage}>
                        <strong>Send</strong>
                    </button>
                    :
                    <div style={{ justifyContent: "center", alignItems: "center" }}><img src={loadingGif} style={{ width: "50px" }} /></div>
                }
            </div>
        );
    }
}


function Dashboard() {

    const [isAllowed, setIsAllowed] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [postsLength, setPostsLength] = useState();


    const [isMobile, setIsMobile] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);

    const redirect = useNavigate();

    useEffect(() => 
    {
      setPageNumber(1);
        if (window.innerWidth <= Number(localStorage.getItem("acceptedSize"))) setIsMobile(true);
        else setIsMobile(false);
    }, []); // initializes the window mode from the first enter

    window.addEventListener("resize", () => 
    {
        if (window.innerWidth <= Number(localStorage.getItem("acceptedSize"))) setIsMobile(true);
        else setIsMobile(false);
    });

    useEffect(() => 
    {
        if (localStorage.getItem("user") == "" || localStorage.getItem("user") == null) redirect("/signin");
    }, []);

    

    if (loaded == false)
    {

        if (localStorage.getItem("user") != "" && localStorage.getItem("user") != null)
        {
            axios.post(`${proxy}/getUserById`, { id: localStorage.getItem("user") })
                .then((res) => 
                {
                    let userData = res.data[0];
                    axios.post(`${proxy}/getPostsByUserId`, { id: localStorage.getItem("user") })
                        .then((res) => 
                        {
                            let userPostsLength = res.data.length;
                            setPostsLength(userPostsLength);
                            setUserInfo(parseSQLArrayProperties(userData));
                            setLoaded(true);
                        })
                        .catch((err) => console.error(err));
                })
                .catch((err) => console.error(err));
        }
        else redirect("/signin");
    }

    const [selectedOption, setSelectedOption] = useState("User Settings");


    let list = (loaded == true && arrayIncludes(userInfo.roles, "admin")) ? 
        [
            "User Settings",
            "Analytics",
            "Bans",
            "Ban Appeals",
            "Moderators",
            "Moderator Acceptance",
            "Send Message"
        ]
        :
        (loaded == true && arrayIncludes(userInfo.roles, "mod")) ?
            [
                "User Settings",
                "Analytics",
                "Bans",
                "Ban Appeals",
                "Send Message"
            ]
            :
            [
                "User Settings",
                "Analytics",
                
            ];

        const sepcialBtn = undefined;

        if (sepcialBtn != undefined && sepcialBtn != "") list = [...list, sepcialBtn];

    function selectItem(value)
    {
        setPageNumber(2);
        setSelectedOption(value)
    }

    if (loaded == false) return (<Loading />);

  return (
    <>

        <div className="dashboard-container">

            <div className="dashboard-options-container" style={(isMobile && pageNumber == 1) ? { width: "100%", border: "1px transparent" } : (isMobile && pageNumber == 2) ? { display: "none" } : {}}>
                <div className="dashboard-details">
                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column", margin: "10px" }}>
                        <span style={{ fontWeight: "bold", fontSize: "18px" }}>Dashboard</span>
                        <span style={{ color: "gray" }}>{userInfo.follows} followers | {postsLength} Memes</span>
                    </div>
                    <div className="list-list">
                        {
                            list.map((value, index) => <button className={(selectedOption == value && !isMobile) ? "list-item list-item-selected" : "list-item list-item-interactable"} style={(sepcialBtn != undefined && sepcialBtn != "" && value == sepcialBtn) ? {  } : {}} onClick={() => selectItem(value)}>{value}</button>)
                        }
                    </div>
                </div>
            </div>
            <div className="dashboard-content-container" style={(isMobile && pageNumber == 2) ? { width: "100%" } : (isMobile && pageNumber == 1) ? { display: "none" } : {}}>
                <div className="dashboard-content"> 
                    <div style={(isMobile) ? { display: "flex", justifyContent: "flex-start", alignItems: "center", margin: "5px", width: "100%" } : { display: "none" }}><button className="back-btn" onClick={() => setPageNumber(1)}>Back</button></div> <br /><br />
                    <RenderDetails option={selectedOption.toLowerCase()} style={{ height: "100vh", padding: "10px" }} />
                </div>
            </div>



            {
                (isAllowed) ?
                    <div></div>
                    :
                    <div>
                        <br /><br />
                        <div className="newpost-container">
                            <div className="newpost">
                                <span style={{ fontWeight: "bold", fontSize: "18px" }}>Welcome to Dashboard!</span> <br /> <br />
                                <p>Hello and welcome to Memedify Dashboard! <br />This is a place for Meme uploaders to check their Posts' satistics and control their profile. To be able to create memes and post in Memedify, be able to gain followers and boosts, you have to enable the <strong>Creator</strong> ability. <br /> <br /> To do that, click the account icon from the menu, <strong>{">"}</strong> Creator Abilities <strong>{">"}</strong> Enable Creator Abilities <strong>{">"}</strong> Agree to the Terms of Service <br /><br />and done! You are now a creator!</p>
                            </div>
                        </div>
                    </div>
            }
        </div>
    </>
  );
}

export default Dashboard;