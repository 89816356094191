import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { reverseString } from '../custom-modules/customModules';
import './styles/search.scss';

import axios from 'axios';

import Post from '../customComponents/Post';

import notFoundIcon from '../Assets/general/maginifing-notfound.png';

import { serverAddress as proxy, filterRepeats, parseSQLArrayProperties } from '../custom-modules/customModules';

import { useSelector, useDispatch } from 'react-redux';
import { actionCreators } from '../redux/actionCreators';
import { bindActionCreators } from 'redux';

import Loading from '../customComponents/Loading';



function Search() 
{
  const [loaded, setLoaded] = useState(false);
  const [loadedPosts, setLoadedPosts] = useState([]);
  const [loadPostsStatus, setLoadPostsStatus] = useState("unloaded");
  const [userInfo, setUserInfo] = useState(null);
  // const [searchInput, setSearchInput] = useState("");

  const redirect = useNavigate();

  const searchInput = window.location.pathname.slice(7, window.location.pathname.length).replaceAll("/", "").replaceAll("%20", " ");
  useEffect(() => 
  {
    if (searchInput == "" || searchInput == undefined) redirect("/");
  }, []);

  useEffect(() => 
  {
    setLoaded(false);
  }, [window.location.pathname]);

  // const reduxState =  useSelector((state) => state);

  // const searchText = reduxState.searchString;
  // const dispatch = useDispatch();


  // const { setCoins, incrementCoins, decrementCoins, setCurrentPage, setSearch } = bindActionCreators(actionCreators, dispatch);

  if (!loaded)
  {
        axios.post(`${proxy}/getPostsBySearchInput`, { search: searchInput.replaceAll(" ", "").toLowerCase() })
            .then((res) => 
            {
                let data = res.data.reverse();
                let allPosts = [];
                for (let i = 0; i < res.data.length; i += 1)
                {
        
                    allPosts = [...allPosts, {
                      user: "",
                      user_id: data[i].user_id,
                      post_id: data[i].post_id,
                      title: data[i].title,
                      description: data[i].description,
                      source: `${proxy}/src/${data[i].src}`,
                      like: data[i].likes,
                      dislike: data[i].dislikes,
                      boosts: data[i].boosts,
                      type: data[i].type,
                      tags: JSON.parse(data[i].tags),
                      status: data[i].status,
                      date_of_creation: data[i].date_of_creation
                    }];
                }
        
                let users = [];
                data.map((value, index) => users[index] = value.user_id);
                users = filterRepeats(users);
                
                axios.post(`${proxy}/getUsernames`, { users: users })
                    .then((res) => 
                    {
                      let usernames = [];
                      
                      for (let j = 0; j < res.data.length; j += 1)
                      {
                        (usernames.length == 0) ?
                          usernames = [{ id: res.data[j].user_id, username: res.data[j].username }]
                          :
                          usernames = [...usernames, { id: res.data[j].user_id, username: res.data[j].username }];
                      }
        
                      for (let x = 0; x < allPosts.length; x += 1)
                      {
                        for (let y = 0; y < usernames.length; y += 1)
                        {
                          if (allPosts[x].user_id == usernames[y].id) allPosts[x].user = usernames[y].username;
                        }
                      }
        
        
                      if (localStorage.getItem("user") != "" && localStorage.getItem("user") != null)
                      {
                        axios.post(`${proxy}/getUserById`, { id: localStorage.getItem("user") })
                        .then((res) => 
                        {
                          setLoadedPosts(allPosts.reverse());
                          setLoaded(true);
                          setUserInfo(parseSQLArrayProperties(res.data[0]));
                        })
                        .catch((err) => console.error(err));
                      }
                      else 
                      {
                        setLoadedPosts(allPosts.reverse());
                        setLoaded(true);
                      }
                    });
            })
            .catch((err) => console.error(err));
    }


    if (!loaded) return <Loading />;

    console.log(loadedPosts);

  return (
    <div style={{ textAlign: "left", margin: "10px" }}>
      {(loadedPosts.length > 0) ? <><strong style={{ fontSize: "20px" }}>Results for "{searchInput}":</strong> <br /> <span style={{ color: "gray", fontSize: "12px" }}>Memes with this title, description, and tags will apear.</span> <br /> <hr /> <br /></> : <></>}
      {
        (loadedPosts.length > 0) ?
          loadedPosts.map((value, index) => <Post descriptionObject={value} userInfo={userInfo} />)
          :
          <div className="content-page"><img src={notFoundIcon} style={{ maxWidth: "200px" }} /><h3>No Memes were found by the term "{searchInput}"</h3><span>Try editing the term you are searching for.</span></div>
      }
    </div>
  );
}

export default Search;