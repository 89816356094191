import React, { useState } from 'react';

function TipDidUKnow() {

    const tips = [
        "Post understandable and relatable memes to get more likes and more boosts!",
        "Try to not really describe your meme; leave it out for people to understand it. Leaving out the title and description part of your post, is an option!",
        "Sometimes memes are understandable by people of a nation, not the whole world. You can specify which nation it is related to when creating a post.",
        "Try to upload short Memes; it is more understandable and people don't get tired of watching your memes.",
        "If your post gets deleted or you got banned from Memedify and you think it was a mistake, let us know in the Memedify discord server."
    ];

    const duks = [
        "The Front-End and Back-End of Memedify, was built by PopularSun.",
        "Memedify was originally an idea that wasn't going to be created at first, but then after working on it a little bit, the decision was made to create it.",
        "ORDIOUS is a community built by PopularSun, for project collaborators to collaborate on future projects.",
        "Memedify is a newborn project so do not excpect it to be the best at first!",
        "Trying to show your personal information or someone else's information, could get you banned from the platform.",
        "When Memedify was in development, it was originally named MemeHub; but because it was taken, it was the renamed to Memedify."
    ];

    const [tip, setTip] = useState(geretaeRandomTipDUK(tips, duks));

    
    // window.addEventListener("keypress", (key) => 
    // {
    //     let keyValue = key.key;
    //     if (key.key.toLowerCase() == "g")
    //     {
    //         setTip(geretaeRandomTipDUK(tips, duks));
    //         keyValue = "";
    //     }
    // });


    function geretaeRandomTipDUK(tips, duks)
    {
        let allArr = [[], tips, duks];

        let randomIndex = Math.floor(Math.random() * 2) + 1;

        let arr = allArr[randomIndex];
        console.log(randomIndex);
        return { title: (randomIndex == 1) ? "Tip" : "Did You Know", desc: arr[Math.floor(Math.random() * arr.length)] };
    }

    console.log(tip[0]);

  return (
    <div className="tip-diduknow-container" style={{ display: "flex", justifyContent: "center" }}>
        <div className="tip-diduknow"  style={{ width: "250px" }}>
            <strong>{tip.title}</strong> <br />
            <p>{tip.desc}</p>
        </div>
    </div>
  );
}

export default TipDidUKnow;