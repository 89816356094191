import React from 'react';
import "./styles/loadingSmall.scss";

import loader from './../Assets/general/loader.gif';


function LoadingSmall({ size }) 
{
  return (
    <img src={loader} style={(size) ? { width: size } : { width: "30px" }} />
  );
}

export default LoadingSmall;