import React, { useState, useEffect } from 'react';
import Loading from '../customComponents/Loading';
import axios from 'axios';
import { serverAddress as proxy, parseSQLArrayProperties, filterRepeats } from '../custom-modules/customModules';


import { useSelector, useDispatch } from 'react-redux';
import { actionCreators } from '../redux/actionCreators';
import { bindActionCreators } from 'redux';

import Post from '../customComponents/Post';

import './styles/search.scss';


// function Search() 
// {
//   let searchText;


//   const [matchedWords, setMatchedWords] = useState({
//     matchedTitles: [],
//     matchedDescriptions: []
//   });






//   function destructureWords(value)
//   {
//       value = value.toString();
//       let words = [];
//       let arrayIndex = 0;

//       for (let i = 0; i <= value.length; i += 1)
//       {
//           if (value.charAt(i) == " ")
//           {
//               arrayIndex += 1;
//               i += 1;            
//           }
          
//           if (words[arrayIndex] == undefined) words[arrayIndex] = value.charAt(i);
//           else words[arrayIndex] = words[arrayIndex] + value.charAt(i);
//       }

//       return words;
//   }

//   function restructureWords(value)
//   {
//     let sentence = "";
//     let arrayIndex = 0;

//     for (let i = 0; i < value.length; i += 1)
//     {
//       i == value.length - 1 ? sentence = sentence + value[i] : sentence = sentence + value[i] + " ";
//     }

//     return sentence;
//   }

//   function includesWords(value, word)
//   {
//     value = value.replaceAll("?", "").replaceAll("!", "").replaceAll(",", "").replaceAll(".", "");
//     let valueArray = destructureWords(value);


//     for (let i = 0; i < valueArray.length; i += 1)
//     {
//       if (valueArray[i].toLowerCase() == word.toLowerCase()) return true;
//     }

//     return false;
//   }

//   let AllPosts = JSON.parse(localStorage.getItem("test1Post"));

//   let [confirmedTitles, confirmedDescriptions] = search(AllPosts, searchText);
//   console.log(confirmedTitles.sort());
  
//   function search(posts, word)
//   {
//     let matchedTitles = [];
//     let matchedDescriptions = [];

//     for (let i = 0; i < posts.length; i += 1)
//     {
//       if (posts[i].title.toLowerCase().replaceAll(" ", "").includes(word.toLowerCase().replaceAll(" ", "")))
//       {
        
//           matchedTitles = [...matchedTitles, posts[i]];
//       }
//     }

//     for (let i = 0; i < posts.length; i += 1)
//     {
//       for (let j = 0; j < matchedTitles.length; j += 1)
//       {
//         if (posts[i].title == matchedTitles[j].title) i += 1;
//       }

//       if (posts[i].description.toLowerCase().replaceAll(" ", "").includes(word.toLowerCase().replaceAll(" ", "")))
//       {
//           matchedDescriptions = [...matchedDescriptions, posts[i]];
        
//       }
//     }

//     return [matchedTitles, matchedDescriptions];
//   }


//   let sortedTitles = confirmedTitles.length == 0 ? [] : sortByLikes(confirmedTitles);
//   let sortedDescriptions = confirmedDescriptions.length == 0 ? [] : sortByLikes(confirmedDescriptions);

//   function sortByLikes(array)
//   {
//     let unsotred = true;
//     do
//     {
//       unsotred = false;

//       for (let i = 0; i < array.length - 1; i += 1)
//       {
//         if (array[i].like > array[i + 1].like)
//         {
//           let nextIndex = array[i + 1];
//           let presentIndex = array[i];

//           array[i + 1] = presentIndex;
//           array[i] = nextIndex;

//           unsotred = true;
//         }
//       }

//     } while (unsotred == true);

//     return array.reverse();
//   }


//   return (
//     <>
//         <Post />
//     </>
//   );
// }

// export default Search;

function Test()
{
  const [posts, setPosts] = useState([{}]);
  const [fetched, setFetched] = useState(false);
  const [loadedPosts, setLoadedPosts] = useState([]);
  const [userInfo, setUserInfo] = useState(null);

  const [panelSize, setPanelSize] = useState(1);
  const [nationality, setNationality] = useState("international");

  if (fetched == false)
  {

        axios.post(`${proxy}/getPosts`)
        .then((res) => 
        {
          let data = res.data;
          if (nationality != "international")
          {
            let newDat = [];
            // let newIndex = 0;

            for (let i = 0; i < data.length; i++)
            {
              if (data[i].nationality == nationality)
              {
                if (newDat.length == 0) newDat = [data[i]];
                else newDat = [...newDat, data[i]];
              }
            }

            data = newDat;
          }

          let allPosts = [];
          for (let i = 0; i < data.length; i += 1)
          {

              allPosts = [...allPosts, {
                user: "",
                user_id: data[i].user_id,
                post_id: data[i].post_id,
                title: data[i].title,
                description: data[i].description,
                // source: require(`./../uploads/${data[i].src}`),
                source: `${proxy}/src/${data[i].src}`,
                like: data[i].likes,
                dislike: data[i].dislikes,
                boosts: data[i].boosts,
                type: data[i].type,
                tags: JSON.parse(data[i].tags),
                status: data[i].status,
                date_of_creation: data[i].date_of_creation
              }];
          }

          let users = [];
          data.map((value, index) => users[index] = value.user_id);
          users = filterRepeats(users);
          
          axios.post(`${proxy}/getUsernames`, { users: users })
              .then((res) => 
              {
                let usernames = [];
                
                for (let j = 0; j < res.data.length; j += 1)
                {
                  (usernames.length == 0) ?
                    usernames = [{ id: res.data[j].user_id, username: res.data[j].username }]
                    :
                    usernames = [...usernames, { id: res.data[j].user_id, username: res.data[j].username }];
                }

                for (let x = 0; x < allPosts.length; x += 1)
                {
                  for (let y = 0; y < usernames.length; y += 1)
                  {
                    if (allPosts[x].user_id == usernames[y].id) allPosts[x].user = usernames[y].username;
                  }
                }


                
                
                if (localStorage.getItem("user") != "" && localStorage.getItem("user") != null)
                {
                  axios.post(`${proxy}/getUserById`, { id: localStorage.getItem("user") })
                  .then((res) => 
                  {
                    setLoadedPosts(allPosts.reverse());
                    setUserInfo(parseSQLArrayProperties(res.data[0]));
                    setFetched(true);
                  })
                  .catch((err) => console.error(err));
                }
                else 
                {
                  setLoadedPosts(allPosts.reverse());
                  setFetched(true);
                }
              });
          
          // console.log(allPosts);
        })
        .catch((err) => console.error(err));
  }

  if (!fetched) return <span>loading...</span>

  return (<div style={{ margin: "5px" }}><Post descriptionObject={loadedPosts[(loadedPosts.length - 1) - 0]} userInfo={userInfo} /></div>);
}

export default Test;

/* 
  video(guyg, k);

  
*/