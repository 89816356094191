


function reducer(state = localStorage.getItem("categories") == null ? [] : JSON.parse(localStorage.getItem("categories")), action)
{
    switch (action.type)
    {
        case "selectCategory":
            {
                let list = state;

                for (let i = 0; i < list.length; i += 1)
                {
                    if (list[i].selected == true) list[i] = { ...list[i], selected: false };
                }

                return list.map((value, index) =>
                {


                        if (action.payload.categoryOrder === index)
                        {
                            return { ...value, selected: true };
                        } 
                        else return value;
                    
                });
            }

        default:
            return state;
    }
}

export default reducer;