// COINS: Increment / Decrement
export function setCoins(amount)
{
    return (dispatch) =>
    {
        dispatch(
            {
                type: "setCoin",
                payload: amount
            });
    };
}

export function incrementCoins(amount)
{
    return (dispatch) =>
    {
        dispatch({
                type: "incrementCoin",
                payload: amount
            });
    };
}

export function decrementCoins(amount)
{
    return (dispatch) =>
    {
        dispatch({
                type: "decrementCoin",
                payload: amount
            });
    };
}

export function setCurrentPage(page)
{
    return (dispatch) => 
    {
        dispatch({
                type: "changePage",
                payload:
                {
                    currentPage: page
                }
            });
    };
}

export function setCurrentCategory(categoryName, categoryOrder)
{
    return (dispatch) =>
    {
        dispatch({
            type: "selectCategory",
            payload:
            {
                categoryName: categoryName,
                categoryOrder: categoryOrder
            }
        });
    };
}

export function setSearch(value)
{
    return (dispatch) => 
    {
        dispatch({
            type: "setSearch",
            payload: {
                searchString: value
            }
        });
    };
}