import React from 'react';
import "./styles/badges.scss"

import { arrayIncludes } from '../custom-modules/customModules';

// badges import
import teamBadgePic from '../Assets/badges/team.png';
import modBadgePic from '../Assets/badges/mod.png';
import adminBadgePic from '../Assets/badges/admin.png';
import easmBadgePic from '../Assets/badges/easm.png';


function Badges({ roles }) 
{
  return (
    <div className="badge-container" style={(roles.length == 0) ? { display: "none" } : {}}>
        {(arrayIncludes(roles, "team") ? <img src={teamBadgePic} style={{ width: "25px" }} /> : <></>)}
        {(arrayIncludes(roles, "admin") ? <img src={adminBadgePic} style={{ width: "25px" }} /> : <></>)}
        {(arrayIncludes(roles, "mod") ? <img src={modBadgePic} style={{ width: "25px" }} /> : <></>)}  
        {(arrayIncludes(roles, "easm")) ? <img src={easmBadgePic} style={{ width: "25px" }} /> : <></>}
    </div>
  );
}

export default Badges;