import React, { useState, useEffect, useCallback, useRef } from 'react';
import Dropzone from 'react-dropzone';
import { useNavigate } from 'react-router-dom';

import LoadingBar from '../customComponents/LoadingBar';

import axios from 'axios';

import { serverAddress as proxy, formatBytes } from '../custom-modules/customModules';

import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../redux/actionCreators';

import './styles/newPost.scss';

import { countries } from '../custom-modules/countries';

import titleIcon from '../Assets/post/title-icon.png';
import descriptionIcon from '../Assets/post/description-icon.png';
import sourceIcon from '../Assets/post/source-icon.png';
import internationalIcon from '../Assets/post/international.png';
import imageIcon from '../Assets/post/image-icon.png';
import videoIcon from '../Assets/post/video-icon.png';

import redTrashCan from '../Assets/post/trashcan-red.png';
import whiteTrashCan from '../Assets/post/trashcan-white.png';

import  hashtagIcon from '../Assets/post/hashtag.png';
import { v4 as uuid } from 'uuid';

import greenCheck from '../Assets/general/green-check.png';
import failedUpload from '../Assets/general/upload-failed.png';


function PreparePost({ passInfo }) 
{

    const [allErrors, setAllErrors] = useState([]);
    const [titleInput, setTitleInput] = useState({ value: "", length: 32 });
    const [descriptionInput, setDescriptionInput] = useState({ value: "", length: 512 });
    const [type, setType] = useState("");
    const [lengthProperties, setLengthProperties] = useState({ 
        title: {
            currentLength: 0,
            maxLength: titleInput.length
        },
        description: {
            currentLength: 0,
            maxLength: descriptionInput.length
        }
     });
    
    const [hashtags, setHashtags] = useState([]);
    const [tempImg, setTempImage] = useState("");
    const [hashtagInput, setHashtagInput] = useState("# ");

    const [errorLabels, setErrorLabels] = useState({
        titleError: "",
        descriptionError: "",
        sourceError: ""
    });

    const hashtagsElement = useRef();

    const [nationality, setNationality] = useState("international");


    function analyzieWords(value)
    {
        value = value.toString();
        let words = [];
        let arrayIndex = 0;

        for (let i = 0; i <= value.length; i += 1)
        {
            if (value.charAt(i) == " ")
            {
                arrayIndex += 1;
                i += 1;            
            }
            
            if (words[arrayIndex] == undefined) words[arrayIndex] = value.charAt(i);
            else words[arrayIndex] = words[arrayIndex] + value.charAt(i);
        }

        return words;
    }

    function checkString(value)
    {
        if ((value.includes("@")) || (value.includes("#"))) return "0001";
        else
        {
            let splittedWords = analyzieWords(value);

            splittedWords.map((val) => 
            {
                if (swearWordDetector(val) != "passed")
                {
                    return "0002";
                }
            });


            return "passed";
        }
    }

    function swearWordDetector(word)
    {
        let swearWords = [
            "fuck",
            "shit",
            "boobs"
        ];

        for (let i = 0; i <= swearWords.length; i += 1)
        {
            if (word.toLowerCase() == swearWords[i])
            {
                return "failed";
            }
        }

        return "passed";
    }

    function setupInputProperties(value, targetedValue, setFunction, maxLength, type)
    {
        let currentLengthValue = value.length;

        let lengthObject = {
            currentLength: currentLengthValue,
            maxLength: maxLength
        };

        if (type == "title")
        {
            setLengthProperties({ ...lengthProperties, title: lengthObject });
        }
        else if (type == "description")
        {
            setLengthProperties({ ...lengthProperties, description: lengthObject });
        }

        let numberOfLineBreaks = (value.match(new RegExp("\n", "g")) || []).length;

        if (numberOfLineBreaks > 6) return;

        setFunction({ ...targetedValue, value: value });

    }
    

    // Dropzone


    // function toggleImageVideo()
    // {
    //     if (fileType == "Image")
    //     {
    //         setFileType("Video");
    //     }
    //     else if (fileType == "Video")
    //     {
    //         setFileType("Image");
    //     }
    //     else
    //     {
    //         console.log(`Error!: Cannot specify the type of the content!`);
    //     }
    // }

    // All abput categories
    const maxLength = 39;
    function onKeyDwn(e) 
    {
            const currentTextLength = e.target.outerText.length;
            console.log(e.target.outerText)
            if (currentTextLength === maxLength && e.keyCode != 8) {
                e.preventDefault();
            }
    };


    function deleteCategory(array, setFunction, index)
    {
        let newArray = [];
        let newArrayIndex = 0;

        for (let i = 0; i < array.length; i += 1)
        {
            if (i === index)
            {
                i += 1;
            }

            if (array[i] == undefined) break;

            newArray[newArrayIndex] = array[i];


            newArrayIndex += 1;
        }

        setFunction(newArray);
    }



    useEffect(() => 
    {

    }, [allErrors]);


    function setupHashtags(value)
    {
        const maxCategories = 3;

        if ((value.charAt(0) != "#") && (value.charAt(1) != " "))
        {
            value = "# " + value;
        }
        else if ((value.charAt(0) == "#") && (value.charAt(1) != " "))
        {
            value = "# " + value.slice(1, value.length);
        }
        else if ((value.charAt(0) != "#") && (value.charAt(1) == " "))
        {
            value = "# " + value.slice(1, value.length);
        }

        let valueWithoutHashtag = value.slice(2, value.length);
        let deniedCharacters = ["#", "@", "*", ";"];


        for (let i = 0; i <= deniedCharacters.length; i += 1)
        {
            if (valueWithoutHashtag.charAt(valueWithoutHashtag.length - 1) == deniedCharacters[i])
            {
                valueWithoutHashtag = valueWithoutHashtag.slice(0, valueWithoutHashtag.lengths - 1);
                break;
            }
        }

        function checkIfExists(val)
        {
            val = "#" + val;
            for (let i = 0; i <= hashtags.length; i += 1)
            {
                console.log(hashtags[i]);
                if (val == hashtags[i]) return true;
            }

            return false;
        }




        if (valueWithoutHashtag.charAt(valueWithoutHashtag.length - 1) == " ")
        {
            if ((hashtags.length >= maxCategories) || (valueWithoutHashtag.length < 3) || (checkIfExists(valueWithoutHashtag) != false))
            {
                valueWithoutHashtag = valueWithoutHashtag.slice(0, valueWithoutHashtag - 1);
            }
            else
            {
                setHashtags([...hashtags, `#${valueWithoutHashtag}`]);
                valueWithoutHashtag = "";
            }
        }



        setHashtagInput("# " + valueWithoutHashtag);


    }



    // SUBMIT

    function nextPage()
    {   
        let notLegit = false;


        setErrorLabels({
            ...errorLabels, 
            titleError: "",
            descriptionError: ""
        });

        let tempErrorLabels = {
            ...errorLabels, 
            titleError: "",
            descriptionError: ""
        };


        // if (titleInput.value == "")
        // {
        //     tempErrorLabels.titleError = "Please fill in the blanks!";
        //     notLegit = true;
        // }

        // if (descriptionInput.value == "")
        // {
        //     tempErrorLabels.descriptionError = "Please fill in the blanks!";
        //     notLegit = true;
        // }


        // if (files.length == 0)
        // {
        //     setErrorLabels({ ...errorLabels, sourceError: "Please upload a file!" });
        //     notLegit = true;
        // }

        // check if the inputs match our expectations!
        if (checkString(titleInput.value) != "passed")
        {
            tempErrorLabels.titleError = "Wrong Characters!";
            notLegit = true;
        }

        if (checkString(descriptionInput.value) != "passed")
        {
            tempErrorLabels.descriptionError = "Wrong Characters!";
            notLegit = true;
        }

        setErrorLabels(tempErrorLabels);

        if (notLegit == true) return;



        // if (files[0].size > translateToBytes(20, "MB"))
        // {
        //     setErrorLabels({ ...errorLabels, sourceError: "The file is too big!" });
        //     notLegit = true;
        // }

        if (notLegit == true) return;


        // If the proccesses pass here, they are ready an approved to be submited
        let newPost = {
            user: localStorage.getItem("user"),
            title: titleInput.value,
            description: descriptionInput.value,
            type: "",
            tags: hashtags
        };

        passInfo("", newPost);

        
        // let allPosts = JSON.parse(localStorage.getItem("posts"));

        // allPosts = [...allErrors, newPost];


        // localStorage.setItem("posts", JSON.stringify(allPosts));

        const date = new Date();

        const post = {
            post_id: uuid(),
            user_id: localStorage.getItem("user"),
            title: titleInput.value,
            desc: descriptionInput.value,
            likes: 0,
            dislikes: 0,
            boosts: 0,
            type: "",
            tags: JSON.stringify(hashtags),
            status: "unverified",
            nationality: nationality,
            src: "",
            date_of_creation: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}-${date.getHours()}:${date.getMinutes()}`,
        };

        localStorage.setItem("newpost-props", JSON.stringify(post));
    }

  return (
    <>
    <br />
    <div className="newpost-container">
        <div className="newpost panel">

            <section>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start"
                }}>
                    <img src={titleIcon} style={{height: "30px"}}></img>
                    <span className="newpost-titles" style={{marginLeft: "5px"}}>Title</span>
                </div> <br />
                <span className="newpost-description">Enter your post's title here!</span> <br /> <br />
                <div className="newpost-input-container">
                    <div className="field" style={{ width: "100%" }}><input type="text" value={titleInput.value} className="input-field" style={{ fontSize: "20px" }} onInput={(e) => setupInputProperties(e.target.value, titleInput, setTitleInput, titleInput.length, "title")} maxLength={titleInput.length} placeholder="(something catchy)"></input></div>
                    <div className="newpost-properties">
                        <span className="newpost-error-label">{errorLabels.titleError}</span>
                        <div><label>{lengthProperties.title.currentLength} / {lengthProperties.title.maxLength}</label></div>
                    </div>
                </div>
            </section>

            <section>
                <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start"
                    }}>
                        <img src={descriptionIcon} style={{height: "30px"}}></img>
                        <span className="newpost-titles" style={{marginLeft: "5px"}}>Description</span>
                </div> <br />
                <span className="newpost-description">Enter description here!</span> <br /> <br />
                <div className="newpost-input-container">
                    <div className="field" style={{ width: "100%" }}><textarea value={descriptionInput.value} className="textarea-field" onChange={(e) => setupInputProperties(e.target.value, descriptionInput, setDescriptionInput, descriptionInput.length, "description")} maxLength={descriptionInput.length} placeholder="(describe your meme!)"></textarea></div>
                    <div className="newpost-properties">
                        <span className="newpost-error-label">{errorLabels.descriptionError}</span>
                        <div><label>{lengthProperties.description.currentLength} / {lengthProperties.description.maxLength}</label></div>
                    </div>
                </div>
            </section>

            <section>
                <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start"
                    }}>
                        <img src={hashtagIcon} style={{height: "30px"}}></img>
                        <span className="newpost-titles" style={{marginLeft: "5px"}}>Category</span>
                </div> <br />
                <span className="newpost-description">You can add up to 3 tags to your post. Press the <strong>SPACE</strong> key to apply your tag! Also, you can click on the added tags to delete them.</span> <br /> <br />
                <div style={{ textAlign: "left" }}>
                    {hashtags.map((value, index) => 
                        <span className="newpost-hashtag" style={{ cursor: "pointer" }} onClick={() => deleteCategory(hashtags, setHashtags, index)}>{value}</span>
                    )}
                </div> <br /> 
                <div className="field"><input value={hashtagInput} className="input-field" onInput={(e) => setupHashtags(e.target.value)} style={{ fontSize: "28px" }} spellCheck="false" maxLength="20"></input></div>
                <div className="newpost-properties">
                    <span className="newpost-error-label"></span>
                    <div><label>{hashtags.length} / 3</label></div>
                </div>
            </section>

            <section>
                <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start"
                    }}>
                        <img src={internationalIcon} style={{height: "30px"}}></img>
                        <span className="newpost-titles" style={{marginLeft: "5px"}}>Nation</span>
                </div> <br />
                <span className="newpost-description">You can specify the nation that the meme content is from. This way, people can filter their reccomendations to only view memes from a single nation. You can also leave it to international for default.</span> <br /> <br />
                <select className="dropdown" onChange={(e) => setNationality(e.target.value)}>
                    <option value="international" selected>International</option>
                    {
                        countries.map((value, index) => <option value={value.code}>{value.name}</option>)
                    }
                    
                </select> <br />
                {/* <div className="newpost-properties">
                    <span className="newpost-error-label"></span>
                    <div><label>{hashtags.length} / 3</label></div>
                </div> */}
            </section>

            

            <div className="newPostSubmitCls">
                <button onClick={nextPage}>Next</button>
            </div>


            
        </div>
    </div>
    </>
  );
}



function NewPost() {
  
  const [setup, setSetup] = useState(false);

  const [size, setSize] = useState({
        currentSize: 0,
        currentSizeInStr: "0 MB",
        maxLength: 8,
        currentSizeInBytes: 0,
        maxLengthInBytes: 8000000
    });

    const [fileType, setFileType] = useState("image");
    const [files, setFiles] = useState([]);
    const [sourceError, setSourceError] = useState("");
    const [tosError, setTosError] = useState("");
    const [tos, setTos] = useState(false);
    const [postInfo, setPostInfo] = useState(undefined);
    const [uploadStatus, setUploadStatus] = useState("not-started");
    const [uploadProgress, setUploadProgress] = useState({ loaded: 0, total: 0, percentage: 0 });
    const [previousNetworkSpeed, setPreviousNetworkSpeed] = useState(0);
    const [networkSpeed, setNetworkSpeed] = useState(0);

    const redirect = useNavigate();



    useEffect(() => 
    {
        if (postInfo != undefined) setSetup(true);

    }, [JSON.stringify(postInfo)]);

  

    function autoSizeTranslator(value)
    {
        // value = parseFloat(value);

        if (value < 1000)
        {
            return value.toFixed(1) + " B";
        }
        else if ((value >= 1000) && (value < Math.pow(1000, 2)))
        {
            return (value / 1000).toFixed(1) + " KB";
        }
        else if ((value >= Math.pow(1000, 2)) && (value < Math.pow(1000, 3)))
        {
            return (value / (Math.pow(1000, 2))).toFixed(1) + " MB";
        }
        else if ((value >= Math.pow(1000, 3)) && (value < Math.pow(1000, 4)))
        {
            return (value / (Math.pow(1000, 3))).toFixed(1) + " GB";
        }
    }

    function clearFiles()
    {
        setFiles([]);
        setSize({ ...size, currentSize: 0, currentSizeInStr: "0 MB",  currentSizeInBytes: 0 });
    }


    function handleOnDrop(acceptedFiles, rejectedFiles)
    {
        let accepted;
        let importedFile;

        // reset the size values
        setSize({ ...size, currentSize: 0, currentSizeInStr: "0 MB", currentSizeInBytes: 0 });

        if (acceptedFiles.length === 0)
        {
            accepted = false;
            return;
        }
        else
        {
            accepted = true;
            importedFile = acceptedFiles[0];
        }

        if (rejectedFiles === 0)
        {
            console.error("Error! Some files are rejected!");
            return;
        }
        if ((selectFromIndex(importedFile.type, 0, 5) != "image/") && (selectFromIndex(importedFile.type, 0, 5) != "video/"))
        {
            return;
        }
        else
        {
            if (selectFromIndex(importedFile.type, 0, 5) == "image/")
            {
                setFileType("image");
            }
            else if (selectFromIndex(importedFile.type, 0, 5) == "video/")
            {
                setFileType("video");
            }
        }

        setSize({ ...size, currentSize: Number(autoSizeTranslator(importedFile.size).slice(0, autoSizeTranslator(importedFile.size).length - 3)), currentSizeInStr: autoSizeTranslator(importedFile.size), currentSizeInBytes: importedFile.size });
        setFiles([importedFile]);
    }

    function selectFromIndex(text, fromIndexOf, toIndex)
    {
        let theText = "";

        if (fromIndexOf < toIndex)
        {
            for (let i = fromIndexOf; i <= toIndex; i += 1)
            {
                theText = theText + text.charAt(i);
            }


            return theText;
        }
        else return "Error!";
    }

    function translateToBytes(value, type)
    {
        value = Number(value);
        type = type.toString();
        type = type.toLowerCase();

        switch (type)
        {
            case "kb":
                return value * Math.pow(1000, 1);
                
            case "mb":
                return value * Math.pow(1000, 2);

            case "gb":
                return value * Math.pow(1000, 3);

            case "tb":
                return value * Math.pow(1000, 4);

            case "pb":
                return value * Math.pow(1000, 5);

            case "eb":
                return value * Math.pow(1000, 6);

            case "zb":
                return value * Math.pow(1000, 7);

            case "yb":
                return value * Math.pow(1000, 8);

            default:
                return value;
        }
    }

    
    function passInfo(e, info)
    {
        setPostInfo(info);
        console.log(info);
    }
    
    // console.log(postInfo);
    
    function goBack()
    {
        setPostInfo(undefined);
        setSetup(false);
    }

    function updateConnectionSpeed()
    {
        const speed = uploadProgress.loaded - previousNetworkSpeed;
        setNetworkSpeed(speed);
        setPreviousNetworkSpeed(speed);
        console.log(formatBytes(speed, 2));
    }
    
    // if (uploadStatus == "uploading")
    // {
    //     setInterval(() => updateConnectionSpeed(), 10000);
    // }
    const btnRef = useRef();

    function post()
    {
        setSourceError("");
        setTosError("");

        let eligable = true;

        if (size.currentSizeInBytes > size.maxLengthInBytes)
        {
            eligable = false;
            setSourceError("The size of the file is bigger than the allowed file size!");
        }
        if (files.length == 0)
        {
            eligable = false;
            setSourceError("There is no file to upload!");
        }
        if (tos == false)
        {
            eligable = false;
            setTosError("You have to accept the Memedify Creators Temrs of Service to be able to post here! It is required to read the Terms of Service before accepting it.")
        }

        if (eligable == false) return;

        var post = localStorage.getItem("newpost-props");
        if (post !== "" && post !== undefined && post !== null)
        {
            setUploadStatus("uploading")
            post = JSON.parse(post);
            post.type = fileType;
        
        // else (kar darim!)
        // ../client/src/uploads

        const formData = new FormData();
        formData.append("newPost", files[0]);
        console.log(files[0]);
        
        
        
        // axios({
        //     method: 'post',
        //     url: `${proxy}/uploadFile`,
        //     data: formData,
            
        //   })

        const config = {
            onUploadProgress: (progressEvent) => 
            {
                const percentage = (progressEvent.loaded / progressEvent.total) * 100;
                setUploadProgress({ ...uploadProgress, loaded: progressEvent.loaded, total: progressEvent.total, percentage: percentage });
                if (percentage == 100)
                {
                    setUploadStatus("uploaded");
                }
            }
        };

        axios.post(`${proxy}/uploadFile`, formData, config)
            .then((res) => 
            {
                post.src = res.data;

                console.log(post);

                axios.post(`${proxy}/submitPost`, post)
                    .then((res) => 
                    {
                        localStorage.setItem("newpost-props", "");
                        // localStorage.setItem("coins-remain", `5/${}`);
                        redirect("/");
                    })
                    .catch((err) => console.error(err));
            })
            .catch((err) => 
            {
                console.error(err)
                setUploadStatus("error");
            });

        }
        
      
    }

    // console.log(JSON.parse(localStorage.getItem("newpost-props")));

    return (
        (setup == false) ? 
            <PreparePost passInfo={passInfo} />
            :
            <div style={{ minHeight: "100vh", height: "100vh" }}>
                <br />
                <div className="newpost-container">
                    <div className="newpost panel">
                        {
                            (uploadStatus == "not-started") ?
                            <div style={{ textAlign: "left" }}><button className="btn" style={{ padding: "10px 20px 10px 20px" }} onClick={goBack}>Back</button></div>
                            :
                            <div style={{ textAlign: "left" }}><button className="btn" style={{ padding: "10px 20px 10px 20px", opacity: 0.5 }}>Back</button></div>
                        }
                        <br />
                        <section>
                            <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start"
                                }}>
                                    <img src={sourceIcon} style={{height: "30px"}}></img>
                                    <span className="newpost-titles" style={{marginLeft: "5px"}}>Source File</span>
                            </div> <br />
                            <span className="newpost-description">You can upload a Picture (.png, .jpg, ...), a Gif (.gif), or a Video (.mp4, .mov, ...). </span> <br /> <br />
                            
                            {(uploadStatus == "not-started") ?
                                <>
                                    <Dropzone onDrop={(accepted, rejected) => handleOnDrop(accepted, rejected)} multiple={false}>
                                    {({ getRootProps, getInputProps }) => (
                                    <div className="newpost-drozone" { ...getRootProps() }>
                                        <form action="/uploadFile" method="POST" enctype="multipart/form-data">
                                            <input { ...getInputProps() } />
                                            <button ref={btnRef} onClick={() => console.log("sds")} style={{ display: "none" }}>submit</button>
                                        </form>
                                        <label>+</label>
                                    </div>
                                    )}
                                    </Dropzone>
                                    <div className="newpost-file-list" style={files.length == 0 ? {display: "none"} : {display: "flex"}}>
                                    {files.map((file, index) => (
                                    <>
                                        <img src={selectFromIndex(file.type, 0, 5).toLocaleLowerCase() == "image/" ? imageIcon : videoIcon} style={{width: "20px"}}></img>
                                        <span className="newpost-file-name" style={{marginLeft: "8px"}}>{file.name}</span>
                                        
                                        <div className="newpost-file-delete">
                                            <button className="del-btn" onClick={clearFiles}>
                                                <img src={redTrashCan} style={{width: "20px"}}></img>
                                            </button>
                                        </div>
                                    </>
                                
                                    
                                    ))}
                            </div>
                                </>
                                :
                                (uploadStatus == "uploading") ? 
                                    <LoadingBar percentage={uploadProgress.percentage} entireSize={formatBytes(uploadProgress.total, 2)} loadedSize={formatBytes(uploadProgress.loaded, 2)} networkSpeed={`${formatBytes(networkSpeed, 2)}/s`} />
                                    :
                                    (uploadStatus == "error") ?
                                    <span style={{ fontWeight: "bold", color: "red", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", textAlign: "center" }}><img src={failedUpload} style={{ maxWidth: "120px" }} />Oh oh! An Error Occured!.<br />An error occured while uploading your Meme! Please check your internet connection and try again!</span>
                                        :
                                        <span style={{ fontWeight: "bold", color: "green", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", textAlign: "center" }}><img src={greenCheck} style={{ maxWidth: "60px" }} />Your Meme was successfully uploaded to Memedify. Please wait for confirmation from the server...<br />Redirecting you to the homepage when done!...</span>}
                            
                            <div className="newpost-properties">
                                <span className="newpost-error-label">{sourceError}</span>
                                <div><label style={files.length == 0 ? {} : files[0].size > translateToBytes(8, "MB") ? {color: "rgb(173, 16, 16)"} : {}}>{size.currentSizeInStr} / {size.maxLength.toString() + " MB"}</label></div>
                            </div>
                        </section> <br />

                        <section>
                                <input type="checkbox" checked={tos} onInput={() => setTos(!tos)} />
                                <label style={{ color: "gray", fontWeight: "bold" }}>By posting this "Meme" to Memedify, I accept the <a href="https://ordious.com/memedify/tos">Memedify Creators Temrs Of Service</a>.</label> <br />
                                <span className="newpost-error-label">{tosError}</span>
                            </section> <br />

                        {(uploadStatus == "not-started") ?
                            <div className="newPostSubmitCls">
                                <button onClick={post}>POST</button>
                            </div>
                            :
                            (uploadStatus == "uploading")?
                                <div className="newPostSubmitCls" style={{ opacity: 0.5 }}>
                                    <button>POST</button>
                                </div>
                                :
                                (uploadStatus == "error") ?
                                    <div className="newPostSubmitCls">
                                        <button onClick={post}>Retry</button>
                                    </div>
                                    :
                                    <></>
                        }
                    </div>
                </div>
            </div>
    );
}

export default NewPost;