import React, { useState, useEffect } from 'react';

import { Outlet, useNavigate } from 'react-router-dom';

import './styles/account.scss';

import { useSelector, useDispatch } from 'react-redux';
import { actionCreators } from '../redux/actionCreators';
import { bindActionCreators } from 'redux';

import Post from '../customComponents/Post';


import Image from '../Assets/0y1pvct3kzz21.png';
import sigaret from '../Assets/reaction.mp4';
import startWarsClip from '../Assets/VADER_._DC_Shared_post_with_mando.mp4';
import aughhh from '../Assets/sources/aughhhhh.mp4';
import chadMusic from '../Assets/sources/chad_music.mp4';
import cyberHeadset from '../Assets/sources/cyberpunkHeadset.jpg';
import cyberMoney from '../Assets/sources/cyberpunkMoney.jpg';
import gtaScene from '../Assets/sources/gtaScene.png';
import ripRainbowPlayer from '../Assets/sources/rainbowSixPlayer.jpg';
import sigaretoMix from '../Assets/sources/reactionMix.mp4';
import ripTechno from '../Assets/sources/riptechno.mp4';

import Loading from '../customComponents/Loading';
import axios from 'axios';
import { serverAddress as proxy, parseSQLArrayProperties, reverseString, filterRepeats, arrayIncludes } from '../custom-modules/customModules';


import ordiousLogo from '../Assets/ordious-icon-o.png';
import memedifyLogo from '../Assets/logo.png';

// custom components
import Badges from '../customComponents/Badges';

import Pfp from '../customComponents/Pfp';

function Account() 
{
  const [loaded, setLoaded] = useState(false);
  const [user, setUser] = useState(null);
  const [loadedPosts, setLoadedPosts] = useState([]);
  const [pathname, setPathname] = useState(window.location.pathname);
  const [roles, setRoles] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => 
    {
        if (window.innerWidth <= Number(localStorage.getItem("acceptedSize"))) setIsMobile(true);
        else setIsMobile(false);
    }, []); // initializes the window mode from the first enter

    window.addEventListener("resize", () => 
    {
        if (window.innerWidth <= Number(localStorage.getItem("acceptedSize"))) setIsMobile(true);
        else setIsMobile(false);
    });

  const redirect = useNavigate();

  useEffect(() => 
  {
    if (localStorage.getItem("user") == "" || localStorage.getItem("user") == null) redirect("/signin");
  }, []);
 
  // let pathname = window.location.pathname;
  // console.log(pathname, pathname.charAt(pathname.length - 1) == "t");

  useEffect(() => 
  {
    let pth = pathname;
    while (pth.charAt(pth.length - 1) === "/")
    {
      pth = pth.slice(0, pth.length - 1);
    }

    setPathname(pth);
  }, []);

  // if (pathname.charAt(pathname.length - 1) === "/")
  // {
  //   pathname = pathname.slice(0, pathname.length - 1);
  // }

  if (loaded == false)
  {

    if (localStorage.getItem("user") != "" && localStorage.getItem("user") != null)
    {
      axios.post(`${proxy}/getUserById`, { id: localStorage.getItem("user") })
        .then((res) => 
        {
          let user = parseSQLArrayProperties(res.data[0]);
          
  
          setUser(user);
          setRoles(user.roles);
          // console.log(user);
  
          // axios.post(`${proxy}/getPostsByUserId`, { id: localStorage.getItem("user") })
          //   .then((res) => 
          //   {
          //     let posts = res.data;
          //     setLoadedPosts(posts);
          //     console.log(posts);
  
          //     setLoaded(true);
          //   })
          //   .catch((err) => console.error(err));
            
            
            
            //
        axios.post(`${proxy}/getPostsByUserId`, { id: localStorage.getItem("user") })
        .then((res) => 
        {
          let data = res.data.reverse();
          let allPosts = [];
          for (let i = 0; i < res.data.length; i += 1)
          {
  
              allPosts = [...allPosts, {
                user: "",
                user_id: data[i].user_id,
                post_id: data[i].post_id,
                title: data[i].title,
                description: data[i].description,
                source: `${proxy}/src/${data[i].src}`,
                like: data[i].likes,
                dislike: data[i].dislikes,
                boosts: data[i].boosts,
                type: data[i].type,
                tags: JSON.parse(data[i].tags),
                status: data[i].status,
                date_of_creation: data[i].date_of_creation
              }];
          }
  
          let users = [];
          data.map((value, index) => users[index] = value.user_id);
          users = filterRepeats(users);
          
          axios.post(`${proxy}/getUsernames`, { users: users })
              .then((res) => 
              {
                let usernames = [];
                
                for (let j = 0; j < res.data.length; j += 1)
                {
                  (usernames.length == 0) ?
                    usernames = [{ id: res.data[j].user_id, username: res.data[j].username }]
                    :
                    usernames = [...usernames, { id: res.data[j].user_id, username: res.data[j].username }];
                }
  
                for (let x = 0; x < allPosts.length; x += 1)
                {
                  for (let y = 0; y < usernames.length; y += 1)
                  {
                    if (allPosts[x].user_id == usernames[y].id) allPosts[x].user = usernames[y].username;
                  }
                }
  
  
                setLoaded(true);
                setLoadedPosts(allPosts);
              });
          
          // console.log(allPosts);
        })
        .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    }
    else redirect("/signin");
  }
  
  const reduxState =  useSelector((state) => state);
  const dispatch = useDispatch();


  const { setCurrentPage } = bindActionCreators(actionCreators, dispatch);


  useEffect(() => 
  {
    setCurrentPage("account");
  }, []);

  let allPosts = JSON.parse(localStorage.getItem("test1Post"));
  
  function logout()
  {
    localStorage.setItem("user", "");
    redirect("/");
    window.location.reload();
  }

  // function specifyPosts(posts, user)
  // {
  //   let arrangedPosts = [];

  //   for (let i = 0; i < posts.length; i += 1)
  //   {
  //     if (posts[i].user == user)
  //     {
  //       arrangedPosts.length == 0 ?
  //         arrangedPosts = [posts[i]]
  //         :
  //         arrangedPosts = [...arrangedPosts, posts[i]];
  //     }
  //   }
  //   return arrangedPosts.reverse();
    
  // }

  // console.log(pathname);
  // console.log((pathname.slice(pathname.length - 7, pathname.length)))
  if (pathname.slice(pathname.length - 7, pathname.length) != "account") return (<Outlet />);
  if (loaded == false) return (<Loading />);
  let pfpdesc = user.profile_desc  // .replace(/(?:\r\n|\r|\n)/g, '<br>');

  return (
    <>
      <div className="account-page-container">
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <div className="badge-container" style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <img style={{ width: "20px" }} src={(user.type == "o") ? ordiousLogo : memedifyLogo} />
            <strong>{(user.type == "o") ? "ORDIOUS" : "Memedify"} Account</strong>
          </div>
        </div>
        <div className="account-head" style={(isMobile) ? { flexDirection: "column", justifyContent: "center" } : {}}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap" }}>
            <Pfp image={user.pfp} />
            <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-start", flexDirection: "column" }}>
              <label className="account-name" style={{ fontWeight: "bold", fontSize: "20px" }}>{(user.display_name) == "" ? "" : user.display_name}</label> 
              <label className="account-username">{user.username}</label>
              <label style={{ color: "gray" }}>{user.follows} follower(s)</label>
              {/* <label style={{ color: "gray" }}>{(user.date_of_creation.slice(0, 10).replaceAll("-", "/"))}</label> */}
            </div>
            <Badges roles={roles} />
            
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <button className="del-btn" onClick={logout}>Log Out</button>
            
          </div>
          
        
          
          
        </div>
        <span style={(user.profile_desc.toString().length <= 0) ? { display: "none" } : { display: "flex", justifyContent: "flex-start", alignItems: "center" }}><strong style={{ fontSize: "12px" }}>ABOUT ME:</strong></span>
        <div style={(user.profile_desc.toString().length <= 0) ? { display: "none" } : {}} className="account-description"><span style={{ overflowWrap: "break-word" }}>{pfpdesc}</span></div>

        <div>
          <div className="account-posts-label-container">
            {/* <label style={{ fontWeight: "bold", fontSize: "25px" }}>Posts from {user.username}:</label> */}
          </div> <br></br>

          <div className="account-posts-container">
            <span style={(loadedPosts.length == 0) ? { display: "block" } : { display: "none" }}>This user never posted!</span>
            {
              loadedPosts.map((value, index) => <div style={{ width: "100%" }}><Post descriptionObject={value} userInfo={user} /></div>)
            }
          </div>
        </div>
      </div>
    </>
  );
}


export default Account;