import React from 'react';
import "./styles/loadingBar.scss";


function LoadingBar({ percentage, entireSize, loadedSize, networkSpeed }) {
  return (
    <div className="loadingbar-container">
        <div className="loadingbar">
            <div className="loadingbar-done" style={{ width: `${percentage}%` }}></div>
        </div>
        <div className="loadingbar-bottom-container">
            <div className="loadingbar-bottom">
                <div className="loadingbar-bottom-left">{(entireSize != undefined && loadedSize != undefined) ? `${loadedSize} / ${entireSize}` : ""}</div>
                {/* <div className="loadingbar-bottom-right">{(networkSpeed != undefined) ? `${networkSpeed}` : ""}</div> */}
            </div>
        </div>
    </div>
  );
}

export default LoadingBar;