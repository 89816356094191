import React, { useEffect, useState } from 'react';
import './styles/follows.scss';
import { useNavigate } from 'react-router-dom';

// Post element
import Post from '../customComponents/Post';

import axios from 'axios';
import { serverAddress as proxy, parseSQLArrayProperties, arrangePosts, removeIndexByValue, arrayIncludes } from '../custom-modules/customModules';
import Loading from '../customComponents/Loading';

// custom modules
import { specifyPosts } from '../custom-modules/customModules';

// redux related
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../redux/actionCreators';

import magClouds from '../Assets/general/magnifing-cloud.png';
import testFollowImg from '../Assets/sources/cyberpunkHeadset.jpg';
import notFoundIcon from '../Assets/general/maginifing-notfound.png';

import Pfp from '../customComponents/Pfp';


function Follows() 
{
  const [loaded, setLoaded] = useState(false);
  const [rawFollowed, setRawFollowed] = useState([]);
  const [followed, setFollowed] = useState([]);
  const [loadedPosts, setLoadedPosts] = useState([]);
  const [loadPostsStatus, setLoadPostsStatus] = useState("unloaded");
  const [currentUser, setCurrentUser] = useState({});
  const [userInfo, setUserInfo] = useState(null);
  
  const [isMobile, setIsMobile] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => 
    {
      setPageNumber(1);
      if (window.innerWidth <= Number(localStorage.getItem("acceptedSize"))) setIsMobile(true);
      else setIsMobile(false);
    }, []); // initializes the window mode from the first enter

  window.addEventListener("resize", () => 
  {
    if (window.innerWidth <= Number(localStorage.getItem("acceptedSize"))) setIsMobile(true);
    else setIsMobile(false);
  });

  useEffect(() => 
  {
      if (localStorage.getItem("user") == "" || localStorage.getItem("user") == null) redirect("/signin");
  }, []);

  const redirect = useNavigate();

  if (loaded == false)
  {

    if (localStorage.getItem("user") != "" && localStorage.getItem("user") != null)
    {
      axios.post(`${proxy}/getUserById`, { id: localStorage.getItem("user") })
        .then((res) => 
        {
          // setUserInfo(parseSQLArrayProperties(res.data[0]));
          let followedUsers = parseSQLArrayProperties(res.data[0]);
          setUserInfo(followedUsers);
          followedUsers = followedUsers.followed;
  
          setRawFollowed(followedUsers);
          
          axios.post(`${proxy}/getUsernames`, { users: followedUsers })
            .then((res) => 
            {
              let followsArray = [];
              for (let i = 0; i < res.data.length; i += 1)
              {
                (followsArray.length == 0)?
                  followsArray = [{ id: res.data[i].user_id, username: res.data[i].username, selected: false }]
                  :
                  followsArray = [...followsArray, { id: res.data[i].user_id, username: res.data[i].username, selected: false }]
              }
  
              console.log(followsArray);
              setFollowedUsers(followsArray);
              // if (followsArray.length != 0) selectUser(followsArray[0].id);
              setLoaded(true);
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    }
    else redirect("/signin");
  }

  


    const [user, setUser] = useState("Erfan");
    const [followedUsers, setFollowedUsers] = useState([
      // {
      //   name: "Erfan",
      //   pfp: undefined,
      //   selected: false
      // },
      // {
      //   name: "Alireza",
      //   pfp: undefined,
      //   selected: false
      // }
    ]);

    // useEffect(() => 
    // {
    //   for (let i = 0; i < followedUsers.length; i += 1)
    //   {
    //     if (followedUsers[i].selected == true)
    //     {
    //       setUser(followedUsers[i].name);
    //       return;
    //     }
    //   }
    // }, [JSON.stringify(followedUsers)])

    const reduxState =  useSelector((state) => state);
    const dispatch = useDispatch();
  
  
    const { setCoins, incrementCoins, decrementCoins, setCurrentPage } = bindActionCreators(actionCreators, dispatch);
  
    useEffect(() => 
    {
      setCurrentPage("follows");
    }, []);

    useEffect(() => 
    {
      if (followedUsers.length <= 0) return;

      // selectUser(followedUsers[0].id);

      // let wasComplete = false;
      // let followed = followedUsers;
      // for (let i = 0; i < followed.length; i += 1)
      // {
      //   if (followed[i].selected == true)
      //   {
      //     wasComplete = true;
      //     return;
      //   }
      // }

      // followed[0].selected = true;
      // setCurrentUser(followed[0].username);

      // setFollowedUsers(followed);

    }, []);

    // let allPosts = JSON.parse(localStorage.getItem("test1Post"));

    function selectUser(id)
    {
      setPageNumber(2);
      let users = followedUsers;

      for (let i = 0; i < users.length; i += 1)
      {
        users[i].selected = false;
      }

      for (let j = 0; j < users.length; j += 1)
      {
        if (users[j].id == id)
        {
          users[j].selected = true;
          setLoadPostsStatus("loading");
          loadPosts(id, users[j].username);
          setCurrentUser({ id: users[j].id, username: users[j].username });
          return;
        }
      }

    }

    function loadPosts(id, username)
    {
      axios.post(`${proxy}/getPostsByUserId`, { id: id })
        .then((res) => 
        {
          let posts = arrangePosts(res.data, username, false);
          setLoadPostsStatus("loaded");
          setLoadedPosts(posts);
        })
        .catch((err) => console.error(err));
    }

    // function unfollow(id)
    // {
    //   let followed = rawFollowed;

    //   followed = removeIndexByValue(followed, id);
    //   axios.post(`${proxy}/modifyUser`, { id: localStorage.getItem("user"), action: "modifyFollowed", property: JSON.stringify(followed) })
    //     .then((res) => 
    //     {
    //       console.log(res.data)
    //       setLoaded(false);
    //     })
    //     .catch((err) => console.error(err));
    // }



    function unfollow(id)
    {
      let followed = rawFollowed;

      if (localStorage.getItem("user") == "" || localStorage.getItem("user") == null) return;
  
      axios.post(`${proxy}/getUserById`, { id: localStorage.getItem("user") })
        .then((res) => 
        {
          let data = parseSQLArrayProperties(res.data[0]);
          let follows = data.followed;
  
          if (arrayIncludes(follows, id))
          {
            (follows.length == 1) ?
              follows = []
              :
              follows = removeIndexByValue(follows, id);
  
              axios.post(`${proxy}/unfollow`, { id: id })
                .then((res) => 
                {
                  axios.post(`${proxy}/modifyUser`, { id: localStorage.getItem("user"), action: "modifyFollowed", property: JSON.stringify(follows) })
                  .then((res) => 
                  {
                      setRawFollowed(follows);
                      setLoaded(false);

                    })
                    .catch((err) => console.error(err));
                })
                .catch((err) => console.error(err));
  
          }
        })
        .catch((err) => console.error(err));
    }





    // function selectUser(index)
    // {
    //   // Find and turn the currently selected user, and deselect it
    //   let followed = followedUsers;

    //   for (let i = 0; i < followed.length; i += 1)
    //   {
    //     followed[i].selected = false;
    //   }

      
    //   // Select the selected option / index
    //   followed[index].selected = true;


    //   setUser(followed[index].name)
    //   setFollowedUsers(followed);
    // }

    

    if (loaded === false) return (<Loading />);

  return (
    <>
        <div className="follows-container">
            <div className="follows-list-container" style={(isMobile && pageNumber == 1) ? { width: "100%" } : (isMobile && pageNumber == 2) ? { display: "none" } : {}}>
              <div className="list-list">
                <div>
                  <br />
                  <span style={followedUsers.length == 0 ? { display: "block" } : { display: "none" }}>
                    <strong>Welcome to the Follows list!</strong> <br /> <br />
                    You can view the recent Memes of users you follow. Click the follow button on a Meme, posted by the user you want to follow, or click on the follow button inside their profile, to follow that person. <br /> <br />
                    <strong>Tip!</strong>: If you want to gain more followers, post more frequently, post relatable memes, and funny memes.
                  </span>
                </div>
                {followedUsers.map((value, index) => 
                <button className={(value.selected == true && isMobile == false) ? "list-item list-item-selected" : "list-item list-item-interactable"} onClick={() => selectUser(value.id)}>
                  <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Pfp size="26px" image={value.pfp} />
                    {/* {value.pfp == undefined ? <div className="follow-image"></div> : <img src={testFollowImg} className="follow-image" />} */}
                    <label>{value.username}</label>
                  </span>
                  <button className="follow-unfollow" onClick={() => unfollow(value.id)}>Unfollow</button>
                </button>)}
            </div>
            </div>
            <div className="follows-post-container" style={(isMobile && pageNumber == 2) ? { width: "100%" } : (isMobile && pageNumber == 1) ? { display: "none" } : {}}>
              <div style={(isMobile) ? { display: "flex", justifyContent: "flex-start", alignItems: "center", margin: "5px", width: "100%" } : { display: "none" }}><button className="back-btn" onClick={() => setPageNumber(1)}>Back</button></div> <br /><br />
              {(loadPostsStatus == "unloaded") ? 
                <div className="content-page"><img src={magClouds} style={{ maxWidth: "200px" }} /><h3>No User Selected.</h3><span>Please select a user from the follows list on the left.</span><span>You can click on the "FOLLOW" button from the users' profiles or on top of their Memes.</span></div>
                :
                (loadPostsStatus == "loading") ?
                  <Loading />
                  :
                  <div>
                    {/* <div style={{ textAlign: "left", fontWeight: "bold", fontSize: "25px" }}><label>Posts from {currentUser.username}!</label></div> <br /> <br /> */}
                    {loadedPosts.length == 0 ? <div className="content-page"><img src={notFoundIcon} style={{ maxWidth: "200px" }} /><h3>This user never posted.</h3></div> : loadedPosts.map((value, index) => <Post descriptionObject={value} showFollowButton={false} userInfo={userInfo} />)}
                  </div>}
              
            </div>
        </div>
    </>
  );
}

export default Follows;