import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

// here we have to import all the combined reducers that we combined in a constant
import reducers from './reducers/combinedReducers';


// this is where we create our store!
export const store = createStore(reducers, applyMiddleware(thunk));


