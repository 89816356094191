import React from 'react';
import './styles/pfp.scss';
import { serverAddress as proxy } from '../custom-modules/customModules';

import defPfp from '../Assets/general/def-pfp.png';

function Pfp({ image, size }) 
{
  // console.log(image);
  return (
    <span className="pfp-image-container" style={(size == undefined) ? { backgroundImage: `url(${(image == undefined || image == null || image == "") ? defPfp : `${proxy}/src/${image}`})` } : { backgroundImage: `url(${(image == undefined || image == null || image == "") ? defPfp : `${proxy}/src/${image}`})`, height: size, width: size }}></span>
  );
}

export default Pfp;