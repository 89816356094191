import  { combineReducers } from 'redux';

// import all the reducers we have
import coinsReducer from './allReducers/coinsReducer';
import currentPageReducer from './allReducers/currentpageReducer';
import currentCategoryReducer from './allReducers/currentcategoryReducer';
import searchReducer from './allReducers/searchRedcuer';


// this is the part where we combine reducers together
const reducers = combineReducers(
    {
        coins: coinsReducer,
        currentPage: currentPageReducer,
        currentCategory: currentCategoryReducer,
        searchString: searchReducer
    });

export default reducers;