import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

// import all the pages to the root index.js file to be rendered in DOM
import Layout from './pages/Layout';
import Home from './pages/Home';
import Categorytable from './pages/Categorytable';
import Follows from './pages/Follows';
import NoPage from './pages/NoPage';
import Test from './pages/Test';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Account from './pages/Account';
import CreatePost from './pages/CreatePost';
import NewPost from './pages/NewPost';
import Docs from './pages/documents/Docs';
import DocsIntroduction from './pages/documents/DocsIntroduction';
import Search from './pages/Search';
import UploadPost from './pages/UploadPost';
import Dashboard from './pages/Dashboard';
import AccountDetails from './pages/AccountDetails';
import Ban from './pages/Ban';
import Notifications from './pages/Notifications';
import BanAppeal from './pages/BanAppeal';

// import the Provider component from the react-redux library to wrap every page in it. this will allow every page to access the reducers
import { Provider } from 'react-redux';
import { store } from './redux/store';

// gathering every component connected to every page to a single component to render in the DOM
export default function App()
{

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="categorytable" element={<Categorytable />} />
          <Route path="follows" element={<Follows />} />
          <Route path="signin" element={<SignIn />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="account" element={<Account />}>
            <Route path=":accountId" element={<AccountDetails />} />
          </Route>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="createpost" element={<CreatePost />}>
            <Route index element={<NewPost />} />
            <Route path="upload" element={<UploadPost />} />
          </Route>
          <Route path="search" element={<Search />}>
            <Route path=":searchId" element={<Search />} />
          </Route>
          {/* <Route path="docs" element={<Docs />}>
            <Route index element={<DocsIntroduction />}></Route>
          </Route> */}
          <Route path="ban" element={<Ban />} />
          <Route path="ban-appeal" element={<BanAppeal />} />
          <Route path="*" element={<NoPage />} />
          <Route path="test" element={<Test />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

