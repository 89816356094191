import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import './styles/sign.scss';

import Axios from 'axios';
import { serverAddress as proxy } from "../custom-modules/customModules";
import { filterText, numberOfCharactersInString } from '../custom-modules/customModules';

import hiddenIcon from '../Assets/general/hidden.png';
import showIcon from '../Assets/general/view.png';
import { stat } from 'fs';



function SignIn() 
{

  const [emailField, setEmailField] = useState("");
  const [passwordField, setPasswordField] = useState("");

  const [viewPassword, setViewPassword] = useState(false);
  const [errorText, setErrorText] = useState({
    generalError: "",
    emailError: "",
    passwordError: ""
  });

  const [detectedAccount, setDetectedAccount] = useState([]);
  const redirect = useNavigate();


  let bannedCharacters = ["#", "/", "*", "$", "^", "&", "(", ")", "+", "-", "!", "~", "`", " "];

  function setupEmailField(value)
  {
    if (filterText(value, bannedCharacters) !== false && numberOfCharactersInString(value, "@") <= 1)
    {
      setEmailField(filterText(value, bannedCharacters));
    }
  }

  function setupPasswordField(value)
  {
    if (filterText(value, ["'", '"', "`"]) !== false) setPasswordField(filterText(value, ["'", '"', "`"]));
  }
  


  function setView(value)
  {
      if (value == false)
      {
          return hiddenIcon;
      }
      else
      {
          return showIcon;
      }
  }

  function signIn()
  {
    setErrorText({
      emailError: "",
      passwordError: "",
    });

    let hasErrors = false;

    let tempErrorText = {
      generalError: "",
      emailError: "",
      passwordError: "",
    };

    if (emailField.includes("@") == false || emailField.includes(".") == false)
    {
      hasErrors = true;
      tempErrorText.emailError = "Email did not contain the necessary characters!";
    }

    if (emailField.length == 0)
    {
      hasErrors = true;
      tempErrorText.emailError = "Please fill in the blank!";
    }

    if (passwordField.length == 0)
    {
      hasErrors = true;
      tempErrorText.passwordError = "Please fill in the blank!";
    }


    setErrorText(tempErrorText);
    if (hasErrors) return;

    Axios.post(`${proxy}/getUser`, { email: emailField, password: passwordField })
      .then((res) => 
      {
        let account = res.data;

        if (account.length == 0) setErrorText({ ...errorText, generalError: "The Email or the Password was wrong!" });
        else if (account.length != 1) setErrorText({ ...errorText, generalError: "Something is wrong with the given data." })
        else
        {
          let newUser = res.data[0];
          // console.log(newUser);
          newUser.followed = JSON.parse(newUser.followed);
          newUser.categories = JSON.parse(newUser.categories);
          newUser.liked_posts = JSON.parse(newUser.liked_posts);
          newUser.disliked_posts = JSON.parse(newUser.disliked_posts);
          newUser.boosted_posts = JSON.parse(newUser.boosted_posts);
          newUser.roles = JSON.parse(newUser.roles);

          setDetectedAccount(account);
          redirect("/");
          window.location.reload();
          console.log(newUser);
          console.log(account[0].user_id);
          localStorage.setItem("user", account[0].user_id);
        }

      })
      .catch((err) => 
      {
        console.error(err);
      });
  }

  return (
    <>
      <br></br>
        <div className="containerCls">
          <div className="signInCls1">
            <div className="sign-container panel">
              <div className="sign-details">
                <h3>Sign in to your Memedify account</h3>
                <span className="sign-title-error">{errorText.generalError}</span> <br /><br />
                <section>
                  <label className="sign-title-label">Email</label> <br />

                  <div className="field">
                    <input type="email" value={emailField} onInput={(e) => setupEmailField(e.target.value)} className="input-field" />
                  </div>
                  <span className="sign-title-error">{errorText.emailError}</span> <br /><br />


                  <label className="sign-title-label">Password</label> <br />
                  
                  <div className="field">
                    <input type={viewPassword == false ? "password" : "text"} value={passwordField} onInput={(e) => setupPasswordField(e.target.value)} className="input-field" />
                    <button onClick={() => setViewPassword(!viewPassword)} style={{ cursor: "pointer", background: "none", border: "none" }}>
                      <img src={setView(viewPassword)} style={{ width: "25px" }} />
                    </button>
                  </div>
                <span className="sign-title-error">{errorText.passwordError}</span><br /> <br />
                </section> 

                {/* <div className="sign-captcha">Captcha is going to be here.................</div> <br /> */}
                <button className="sign-btn btn" onClick={signIn}>Sign In</button> <br /> <br />
                <div style={{ textAlign: "left" }}><label>Don't have an account? <Link to="/signup"><span>Sign Up</span></Link>!</label></div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default SignIn;