import React from 'react';
import './styles/loading.scss'

function Loading() {
  return (
    <div className="loading-panel">
      <div class="lds-dual-ring"></div>
    </div>
  );
}

export default Loading;