function reducer(state = 66, action)
{
    switch (action.type)
    {
        case "incrementCoin":
                return Number(state) + Number(action.payload);

        case "decrementCoin":
            return Number(state) - Number(action.payload);

        case "setCoin":
            return Number(action.payload);

        default:
            return state;
    }
}

export default reducer;