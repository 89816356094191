import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../redux/actionCreators';
import Condition from '../customComponents/Condition';

import redTrashCan from '../Assets/post/trashcan-red.png';
import whiteTrashCan from '../Assets/post/trashcan-white.png';

// custom modules
import { specifyPosts, serverAddress as proxy, parseSQLArrayProperties, filterRepeats, removeIndex, removeIndexByValue, arrayIncludes } from '../custom-modules/customModules';
import Loading from '../customComponents/Loading';

import Post from '../customComponents/Post';

import './styles/categorytable.scss';

import plusIcon from '../Assets/general/addCategory.png';
import hashWhite from '../Assets/general/hashtag-white.png';
import hashBlack from '../Assets/general/hashtag-black.png';
import notFoundIcon from '../Assets/general/maginifing-notfound.png';
import magClouds from '../Assets/general/magnifing-cloud.png';


function Categorytable() 
{   


    const listElement = useRef();

    const reduxState =  useSelector((state) => state);
    const dispatch = useDispatch();

    // const categoryList = reduxState.currentCategory;

    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(categoryList.length == 0 ? undefined : categoryList[0].title);



  
  
    const { setCoins, incrementCoins, decrementCoins, setCurrentPage } = bindActionCreators(actionCreators, dispatch);
  
    useEffect(() => 
    {
        setCurrentPage("categorytable");
    }, []);

    const [loaded, setLoaded] = useState(false);
    const [categories, setCategories] = useState([]);
    const [rawCategories, setRawCategories] = useState([]);
    const [loadedPosts, setLoadedPosts] = useState([]);
    const [currentCategory, setCurrentCategory] = useState("");
    const [conditionStatus, setConditionStatus] = useState(false);
    const [loadPostsStatus, setLoadPostsStatus] = useState("unloaded");
    const [userInfo, setUserInfo] = useState(null);

    const [isMobile, setIsMobile] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => 
    {
      setPageNumber(1);
        if (window.innerWidth <= Number(localStorage.getItem("acceptedSize"))) setIsMobile(true);
        else setIsMobile(false);
    }, []); // initializes the window mode from the first enter

    window.addEventListener("resize", () => 
    {
        if (window.innerWidth <= Number(localStorage.getItem("acceptedSize"))) setIsMobile(true);
        else setIsMobile(false);
    });



    const redirect = useNavigate();

    useEffect(() => 
    {
        if (localStorage.getItem("user") == "" || localStorage.getItem("user") == null) redirect("/signin");
    }, []);


    if (loaded == false)
    {

        if (localStorage.getItem("user") != "" && localStorage.getItem("user") != null)
        {
            axios.post(`${proxy}/getUserById`, { id: localStorage.getItem("user") })
                .then((res) => 
                {
                    // (parseSQLArrayProperties(res.data[0]));
                    let userCategories = [];
                    let data = parseSQLArrayProperties(res.data[0]);
                    setUserInfo(data);
                    data = data.categories;
    
                    for (let i = 0; i < data.length; i += 1)
                    {
                        (userCategories.length == 0) ?
                            userCategories = [{ value: data[i], selected: false }]
                            :
                            userCategories = [...userCategories, { value: data[i], selected: false }];
                    }
    
                    setRawCategories(data);
                    setCategories(userCategories);
                    if (userCategories.length > 0) setCurrentCategory(userCategories[0]);
                    setLoaded(true);
                })
                .catch((err) => console.error(err));
        }
        else redirect("/signin");
    }

    function selectCategory(category)
    {
        setPageNumber(2);
        let userCategories = categories;

        for (let i = 0; i < userCategories.length; i += 1)
        {
            userCategories[i].selected = false;
        }

        for (let j = 0; j < userCategories.length; j += 1)
        {
            if (userCategories[j].value == category)
            {
                userCategories[j].selected = true;
                loadPosts(userCategories[j].value);
                setLoadPostsStatus("loading");
                setCurrentCategory(userCategories[j].value);
                return;
            }
        }
    }

    function loadPosts(category)
    {
        axios.post(`${proxy}/getPostsByCategory`, { category: category })
            .then((res) => 
            {
                let data = res.data.reverse();
                let allPosts = [];
                for (let i = 0; i < res.data.length; i += 1)
                {
        
                    allPosts = [...allPosts, {
                      user: "",
                      user_id: data[i].user_id,
                      post_id: data[i].post_id,
                      title: data[i].title,
                      description: data[i].description,
                      source: `${proxy}/src/${data[i].src}`,
                      like: data[i].likes,
                      dislike: data[i].dislikes,
                      boosts: data[i].boosts,
                      type: data[i].type,
                      tags: JSON.parse(data[i].tags),
                      status: data[i].status,
                      date_of_creation: data[i].date_of_creation
                    }];
                }
        
                let users = [];
                data.map((value, index) => users[index] = value.user_id);
                users = filterRepeats(users);
                
                axios.post(`${proxy}/getUsernames`, { users: users })
                    .then((res) => 
                    {
                      let usernames = [];
                      
                      for (let j = 0; j < res.data.length; j += 1)
                      {
                        (usernames.length == 0) ?
                          usernames = [{ id: res.data[j].user_id, username: res.data[j].username }]
                          :
                          usernames = [...usernames, { id: res.data[j].user_id, username: res.data[j].username }];
                      }
        
                      for (let x = 0; x < allPosts.length; x += 1)
                      {
                        for (let y = 0; y < usernames.length; y += 1)
                        {
                          if (allPosts[x].user_id == usernames[y].id) allPosts[x].user = usernames[y].username;
                        }
                      }
        
        
                      
                      setLoadedPosts(allPosts);
                      setLoadPostsStatus("loaded");
                    });
            })
            .catch((err) => console.error(err));
    }

    function addCategory(value)
    {
        if (arrayIncludes(rawCategories, value)) return;
        let userCategories = rawCategories;
        (userCategories.length == 0) ?
            userCategories = [value]
            :
            userCategories = [...userCategories, value];

        axios.post(`${proxy}/modifyUser`, { id: localStorage.getItem("user"), action: "modifyCategories", property: JSON.stringify(userCategories) })
            .then((res) => 
            {
                console.log(res.data);
                setLoaded(false);
            })
            .catch((err) => console.error(err));

        setConditionStatus(false);
    }

    function removeCategory(value)
    {
        let userCategories = rawCategories;
        userCategories = removeIndexByValue(userCategories, value);

        axios.post(`${proxy}/modifyUser`, { id: localStorage.getItem("user"), action: "modifyCategories", property: JSON.stringify(userCategories) })
            .then((res) => 
            {
                console.log(res.data);
                setLoaded(false);
            })
            .catch((err) => console.error(err));
    }



    // function selectedCategory()
    // {
    //     if (categoryList.length == 0) return;
    //     let categories = categoryList;

    //     for (let i = 0; i < categories.length; i += 1)
    //     {
    //         if (categories[i].selected == true) return categories[i];
    //     }

    //     categories[0].selected = true;
    //     setCategoryList(categories);
    //     return categories[0];
    // }

    // function selectCategory(index)
    // {
    //     let categories = categoryList;

    //     for (let i = 0; i < categories.length; i += 1)
    //     {
    //         categories[i].selected = false;
    //     }

    //     categories[index].selected = true;
    //     setSelectedCategory(categories[index].title);
    // }

    

    function sortByLikes(array)
    {
      let unsotred = true;
      do
      {
        unsotred = false;
  
        for (let i = 0; i < array.length - 1; i += 1)
        {
          if (array[i].like > array[i + 1].like)
          {
            let nextIndex = array[i + 1];
            let presentIndex = array[i];
  
            array[i + 1] = presentIndex;
            array[i] = nextIndex;
  
            unsotred = true;
          }
        }
  
      } while (unsotred == true);
  
      return array.reverse();
    }


    let AllPosts = JSON.parse(localStorage.getItem("test1Post"));

    // function addCategories(value)
    // {
    //     setConditionStatus(false);
        
    //     let categories = categoryList;

    //     if (categories.length == 0)
    //     {
    //         categories = [{ title: value, selected: true }];
    //     }
    //     else
    //     {
    //         categories = [...categories, { title: value, selected: false }];
    //     }

    //     setCurrentCategory(value);
            

    //     setCategoryList(categories);
    // }

    function categorize(posts, category)
    {
        let matchedPosts = [];

        for (let i = 0; i <= posts.length - 1; i += 1)
        {
            for (let j = 0; j <= posts[i].tags.length - 1; j += 1)
            {
                if (posts[i].tags[j] == category)
                {
                    matchedPosts = [...matchedPosts, posts[i]];
                }
            }
        }

        return matchedPosts;
    }


    // let categorizedPosts = [];
    // if ((categoryList != undefined) && (categoryList.length != 0)) categorizedPosts = categorize(AllPosts, selectedCategory(categoryList).title);
    // categorizedPosts = categorizedPosts.reverse();

    if (loaded === false) return (<Loading />);

  return (
    <>
        <Condition title="Add a tag" hasInput={true} buttonsObjects={[{ title: "Add", btnType: "allow", onClickAction: addCategory }]} status={conditionStatus} onBackgroundClick={() => setConditionStatus(false)} height="105vh" />
        <div className="category-container" >
            <div className="category-list-container" style={(isMobile && pageNumber == 1) ? { width: "100%" } : (isMobile && pageNumber == 2) ? { display: "none" } : {}}>
                <div className="category-introducer">
                    <label>Category Table</label>
                    <button className="category-plus-btn" onClick={() => setConditionStatus(true)} style={{ background: "none", border: "1px transparent", cursor: "pointer" }}>
                        <img className="category-plus-btn-image" src={plusIcon} style={{ width: "30px" }}></img>
                    </button>
                </div>
                <hr></hr>
                
                <div className="list-list">
                    {categories.length != 0 ? categories.map((value, index) => 
                        <button onClick={() => selectCategory(value.value)} className={(value.selected == true && isMobile == false) ? "list-item list-item-selected" : "list-item list-item-interactable"}><span style={{ display: "flex", alignItems: "center" }}><img src={(value.selected == true && !isMobile) ? hashWhite : hashBlack} style={{ width: "15px" }} className="category-list-hashtags" />{value.value.slice(1, value.value.length)}</span> <button className="del-btn"><img src={(value.selected && !isMobile) ? whiteTrashCan : redTrashCan} style={{ width: "15px" }} onClick={() => removeCategory(value.value)} /></button></button>
                    ) : <span>
                            <strong>Welcome to the Category Table!</strong> <br /> <br />
                            If you've payed attention to the Memes, you would see these <span className="newpost-hashtag" style={{ display: "inline" }}>#tags</span> on top of each post. <br /> <br />
                            Well, here you can add these tags to your category list! Then, you can view each post that has that tag! <br /> <br /> <br />
                            Click the plus icon on the top of the list, add your favorite tags, and enjoy the Memes!
                        </span>}
                </div>
            </div>

            <div className="category-post-container" style={(isMobile && pageNumber == 2) ? { width: "100%" } : (isMobile && pageNumber == 1) ? { display: "none" } : {}}>
                <div style={(isMobile) ? { display: "flex", justifyContent: "flex-start", alignItems: "center", margin: "5px", width: "100%" } : { display: "none" }}><button className="back-btn" onClick={() => setPageNumber(1)}>Back</button></div> <br /><br />
                {
                    (loadPostsStatus == "unloaded") ? <div className="content-page"><img src={magClouds} style={{ maxWidth: "200px" }} /><h3>No Categories Selected.</h3><span>Please select a category from the list on the left.</span><span>You can also click on the "+" icon from the top of the list to add a category.</span></div> : (loadPostsStatus == "loading") ? <Loading /> : (loadedPosts.length > 0) ? loadedPosts.map((value, index) => <div style={{ width: "100%" }}><Post descriptionObject={value} userInfo={userInfo} /></div>) : <div className="content-page"><img src={notFoundIcon} style={{ maxWidth: "200px" }} /><h3>No Memes were found with this category.</h3><span>Try adding a category that is more common arround Memers!</span><span>You can also be the first one who uses this category on their Meme!</span></div>
                }
            </div>
        </div>

    </>
  );
}

export default Categorytable;