import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/ban.scss';

import Loading from '../customComponents/Loading';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { serverAddress as proxy, parseSQLArrayProperties, reverseString, filterRepeats, arrayIncludes, removeIndexByValue, removeIndex, filterText, sliceStringByValue, splitByCharacter, correct2DigitsNumbers, convertDate } from '../custom-modules/customModules';

// custom components
import Pfp from '../customComponents/Pfp';

// icon imports
import greenCheck from '../Assets/general/green-check.png';
import notFoundIcon from '../Assets/general/maginifing-notfound.png';
import blockedIcon from '../Assets/general/block.png';

// custom components
import LoadingSmall from '../customComponents/LoadingSmall';



function BanAppeal() 
{
    const redirect = useNavigate();
    const date = new Date();
 
    const [loaded, setLoaded] = useState(false);
    const [allowed, setAllowed] = useState(false);

    const [investigate, setInvestigate] = useState(null);
    const [modUser, setModUser] = useState([]);
    const [targetUser, setTargetUser] = useState([]);
    const [targetUserBanInfo, setTargetUserBanInfo] = useState([]);
    const [permanent, setPermanent] = useState(false);

    // input useStates
    const [reason, setReason] = useState("");

    const [dayDate, setDayDate] = useState(correct2DigitsNumbers(date.getDate() + 1));
    const [monthDate, setMonthDate] = useState(correct2DigitsNumbers(date.getMonth() + 1));
    const [yearDate, setYearDate] = useState(correct2DigitsNumbers(date.getFullYear()));

    const [hourDate, setHourDate] = useState(correct2DigitsNumbers(date.getHours()));
    const [minuteDate, setMinuteDate] = useState(correct2DigitsNumbers(date.getMinutes()));


    const [reasonInputProps, setReasonInputProps] = useState({ current: reason.length, max: 100 });

    const [reasonError, setReasonError] = useState("");
    const [dateError, setDateError] = useState("");


    const [banning, setBanning] = useState(false);
    const [banned, setBanned] = useState(false);

    function applyReason(value)
    {
        let clearVal = filterText(value, ["'", '"', "`"], undefined, reasonInputProps.max);
        if (filterText(value, ["'", '"', "`"], undefined, reasonInputProps.max) !== false) setReason(clearVal);
        else return;
        setReasonInputProps({ ...reasonInputProps, current: clearVal.length });
    }

    function applyDate(value, type)
    {
        value = correct2DigitsNumbers(value);

        switch (type)
        {
            case "date":
                {
                    if (filterText(value, null, null, 2, 1, 31) !== false) setDayDate(filterText(value, null, null, 2));
                }
                break;

            case "month":
                {
                    if (filterText(value, null, null, 2, 1, 12) !== false) setMonthDate((filterText(value, null, null, 2)));
                }
                break;
            case "year":
                {
                    if (filterText(value, null, null, 4, 1) !== false) setYearDate(filterText(value, null, null, 4));
                }
                break;
            case "hour":
                {
                    if (filterText(value, null, null, 2, 0, 23) !== false) setHourDate(filterText(value, null, null, 2));
                }
                break;
            case "minute":
                {
                    if (filterText(value, null, null, 2, 0, 59) !== false) setMinuteDate(filterText(value, null, null, 2));
                }
                break;
        }
    }

    function changeBan(action)
    {
        axios.post(`${proxy}/reviewAppeal`, { appeal_id: targetUserBanInfo.appeal_id })
            .then((res) => 
            {
                let data = res.data;

                switch (action)
                {
                    case "revoke":
                        {
                            axios.post(`${proxy}/setBanStatus`, { id: targetUser.user_id, status: "allowed" })
                                .then((res) => 
                                {
                                    localStorage.setItem("ban-appeal-id", "");
                                    localStorage.setItem("ban-appeal-ban-id", "");
                                    setBanning(false);
                                    setLoaded(false);
                                })
                                .catch((err) => console.error(err));
                        }
                        break;

                    case "dnc":
                        {
                            localStorage.setItem("ban-appeal-id", "");
                            localStorage.setItem("ban-appeal-ban-id", "");
                            setBanning(false);
                            setLoaded(false);
                        }
                        break;

                    case "ban":
                        {
                            let valid = true;

                            setBanning(true);
                            setReasonError("");
                            setDateError("");
                            if (!permanent)
                            {
                                
                                let day = Number(dayDate);
                                let month = Number(monthDate);
                                let year = Number(yearDate);
                                
                                let monthEndDates = [
                                    31,
                                    (year % 4 == 0) ? 29 : 28,
                                    31,
                                    30,
                                    31,
                                    30,
                                    31,
                                    31,
                                    30,
                                    31,
                                    30,
                                    31
                                ];
                    
                                if (year < date.getFullYear())
                                {
                                    valid = false;
                                    setDateError("The entered date is invalid!");
                                }
                                else if (year == date.getFullYear())
                                {
                                    if (month < (date.getMonth() + 1))
                                    {
                                        valid = false;
                                        setDateError("The entered date is invalid!");
                                    }
                                    else if (month == (date.getMonth() + 1))
                                    {
                                        if (day <= date.getDate()) 
                                        {
                                            valid = false;
                                            setDateError("The entered date is invalid!");
                                        }
                                    }
                                }
                    
                                if (day > monthEndDates[month - 1])
                                {
                                    valid = false;
                                    setDateError("This date does not exist!");
                                }
                    
                                // if (day == date.getDate()) setDateError("Temporarily Bans should at least be 1 day!");
                            }
                    
                            // if (reason.toString().length <= 0)
                            // {
                            //     setReasonError("You have to provide a reason for the ban!");
                            //     valid = false;
                            // }
                    
                            
                            if (!valid)
                            {
                                setBanning(false);
                                return;
                            }


                            axios.post(`${proxy}/setBanDate`, {  ban_id: targetUserBanInfo.ban_id, date: (permanent) ? "p" : `${dayDate}/${monthDate}/${yearDate}-${hourDate}:${minuteDate}` })
                                .then((res) => 
                                {
                                    localStorage.setItem("ban-appeal-id", "");
                                    localStorage.setItem("ban-appeal-ban-id", "");
                                    console.log(res.data);
                                    setBanning(false);
                                    setLoaded(false);
                                })
                                .catch((err) => console.error(err));
                        }
                        break;

                }
            })
            .catch((err) => console.error(err));

        

        

        // axios.post(`${proxy}/modifyUser`, { id: targetUser.user_id, action: "modifyStatus", property: "banned" })
        //     .then((res) => 
        //     {
        //         let banProps = {
        //             banId: uuid(),
        //             userId: targetUser.user_id,
        //             username: targetUser.username,
        //             modId: modUser.user_id,
        //             date: (permanent) ? "p" : `${dayDate}/${monthDate}/${yearDate}-${hourDate}:${minuteDate}`,
        //             status: "denied",
        //             reason: reason
        //         };

        //         axios.post(`${proxy}/banUser`, banProps)
        //             .then((res) => 
        //             {
        //                 localStorage.setItem("ban-appeal-id", "");
        //                 setBanned(true);
        //                 setBanning(false);
        //             })
        //             .catch((err) => console.error(err));
        //     })
        //     .catch((err) => console.error(err));

    }

    if (loaded == false)
    {
        if (localStorage.getItem("user") != "" && localStorage.getItem("user") != null)
        {
            axios.post(`${proxy}/getUserById`, { id: localStorage.getItem("user") })
                .then((res) => 
                {
                    if (res.length < 1) redirect("/");
                    let userInfoTemp = parseSQLArrayProperties(res.data[0]);

                    if (arrayIncludes(userInfoTemp.roles, "mod")) setAllowed(true);
                    else setAllowed(false);

                    setModUser(userInfoTemp);

                    setLoaded(true);
                })
                .catch((err) => console.error(err));
        }
        else redirect("/signin");
    }

    if (investigate === true)
    {
        axios.post(`${proxy}/getUserById`, { id: localStorage.getItem("ban-appeal-id") })
            .then((res) => 
            {
                let targetUserTemp = parseSQLArrayProperties(res.data[0]);

                setTargetUser(targetUserTemp);
                setInvestigate(false);

                axios.post(`${proxy}/getBanByBanId`, { ban_id: localStorage.getItem("ban-appeal-ban-id") })
                    .then((res) => 
                    {
                        if (res.data[0].date == "p") setPermanent(true);
                        else 
                        {
                            let [day, month, year] = splitByCharacter(res.data[0].date.toString().slice(0, 10), "/");
                            setDayDate(day);
                            setMonthDate(month);
                            setYearDate(year);
                        }
                        setTargetUserBanInfo(res.data[0]);
                        setLoaded(true);
                    })
                    .catch((err) => console.error(err));
            })
            .catch((err) => console.error(err));
    }







    // loading html

    if (loaded == false) return (<Loading />);
    else if (allowed == false) return (<div className="content-page"><img src={blockedIcon} style={{ maxWidth: "100px" }} /><h3>PERMISSION DENIED</h3></div>);
    else if (localStorage.getItem("ban-appeal-id") == null || localStorage.getItem("ban-appeal-id") == "") return (<div className="content-page"><img src={notFoundIcon} style={{ maxWidth: "200px" }} /><h3>No one to appeal!</h3><span>Go to Dashboard {">"} Ban Apeal {">"} click on a banned user and review their request.</span></div>);
    else if (banned) return (<div className="content-page"><img src={greenCheck} style={{ maxWidth: "100px" }} /><h3>User successfully banned!</h3><br /><br /><span>Thank you for spending your time improving Memedify!<br /> <br /> <br /><button className="btn" style={{ padding: "20px 40px 20px 40px" }} onClick={() => redirect("/")}>Return to Homepage</button></span></div>);
    else if (localStorage.getItem("ban-appeal-id") == modUser.user_id) 
    {
        localStorage.setItem("ban-appeal-id", "");
        window.location.reload();
    }
    else if (investigate === null)
    {
        setInvestigate(true);
        setLoaded(false);
    }

  return (
    <div className="banpage-container" style={{ padding: "20px" }}> <br /> <br />
        <div className="panel" style={{ maxWidth: "600px", margin: "auto" }}>
            <span style={{ fontSize: "20px", fontWeight: "bold" }}>Review Ban</span><br /><br />
            <div className="field" style={{ maxWidth: "300px", margin: "auto" }}><Pfp size="26px" image={targetUser.pfp} />{targetUser.username}</div><br />
            <span>By the moderator</span> <div className="field" style={{ maxWidth: "300px", margin: "auto" }}><Pfp size="26px" image={modUser.pfp} />{modUser.username}</div> <br /> <br />
            <div style={{ textAlign: "left" }}><span className="dashboard-section-title">REASON</span></div>
            <div style={{ textAlign: "left" }}><span style={{ color: "gray", fontSize: "12px" }}>The reason of the ban was provided by the moderator who banned the user.</span></div>
            <div style={{ textAlign: "left" }}>
                <span style={{ whiteSpace: "pre-line" }}>{targetUserBanInfo.reason}</span>
            </div> <br />
            <div style={{ textAlign: "left" }}><span className="dashboard-section-title">APPEAL DESCRIPTION</span></div>
            <div style={{ textAlign: "left" }}><span style={{ color: "gray", fontSize: "12px" }}>This description was written by the person who requested the appeal.</span></div>
            <div style={{ textAlign: "left" }}>
                <span style={{ whiteSpace: "pre-line" }}>{targetUserBanInfo.appeal_desc}</span>
            </div>
            <br /> 
            {/* <div className="newpost-properties">
                <span className="newpost-error-label">{reasonError}</span>
            </div> */}

            {/* <div className="newpost-properties">
                <span className="newpost-error-label"></span>
                <div><label>{reasonInputProps.current} / {reasonInputProps.max}</label></div>
            </div> <br /> */}
            <br /> <br />
            <strong>You can now decide wether to REVOKE, NOT CHANGE, or CHANGE THE SEVERITY OF THE BAN. <br />The user can only appeal to their ban once. <br />By clicking the "Revoke" button, you remove their ban allowing them to use the platform, clicking the "Do Not Change" button does not change their situation, and by clicking on the "Ban {targetUser.username}" the ban will change to the settings below. <br />The ban settings are shown as was set by the moderator.</strong> <br />
            <input type="checkbox" value={permanent} onChange={() => setPermanent(!permanent)} /> <span>Permanent</span> <br /> <br />
            {(!permanent) ? 
                <>
                    <div style={{ textAlign: "left" }}><span style={{ fontSize: "12px", color: "gray" }}>The date and time format is <strong>Day, Month, Year (DD/MM/YYYY)</strong><br />The clock format is a "24-Hour Time" format<br />Bans have to at least be one day or longer</span></div> <br />
                    <div className="banpage-date-container">
            
                        <div className="banpage-date">
                            <span>
                                <span>Day</span><span><div className="field"><input type="number" value={dayDate} className="input-field" onChange={(e) => applyDate(e.target.value, "date")} /></div></span>
                            </span>
                            {/* <span style={{ fontSize: "35px" }}>/</span> */}
                            <span>
                                <span>Month</span><span><div className="field"><input type="number" value={monthDate} className="input-field" onChange={(e) => applyDate(e.target.value, "month")} /></div></span>
                            </span>
                            {/* <span style={{ fontSize: "35px" }}>/</span> */}
                            <span>
                                <span>Year</span><span><div className="field"><input type="number" value={yearDate} className="input-field" style={{ width: "75px" }} onChange={(e) => applyDate(e.target.value, "year")} /></div></span>
                            </span>
                        </div>
                    </div> <br />
                <div className="banpage-date-container">
                    <div className="banpage-date">
                        <span>
                            <span>Hour</span><span><div className="field"><input type="number" value={hourDate} className="input-field" onChange={(e) => applyDate(e.target.value, "hour")} /></div></span>
                        </span>
                        <span style={{ fontSize: "35px" }}>:</span>
                        <span>
                            <span>Minute</span><span><div className="field"><input type="number" value={minuteDate} className="input-field" onChange={(e) => applyDate(e.target.value, "minute")}  /></div></span>
                        </span>
                    </div>
                </div>
                <div className="newpost-properties">
                    <span className="newpost-error-label">{dateError}</span>
                </div>
                </>
                : 
                <></>}
                <br /><br />
                <button className="btn" style={{ padding: "20px 30px 20px 30px", margin: "5px" }} onClick={() => changeBan("revoke")}><strong>Revoke Ban</strong></button>
                <button className="btn" style={{ padding: "20px 30px 20px 30px", margin: "5px" }} onClick={() => changeBan("dnc")}><strong>Do not change</strong></button>
                {
                    (!banning) ?
                        <button className="btn banpage-btn" style={{ margin: "5px" }} onClick={() => changeBan("ban")}>Ban <span>{targetUser.username}</span></button>
                        :
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><LoadingSmall size="50px" /><button className="btn banpage-btn" style={{ opacity: 0.5 }}>Ban <span>{targetUser.username}</span></button></div>
                }       
        </div>
    </div>
  );
}

export default BanAppeal;