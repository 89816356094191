import React, { useEffect } from 'react';
import { Outlet, Link, useNavigate } from "react-router-dom";

function CreatePost() 
{
  const redirect = useNavigate();

  useEffect(() => 
  {
      if (localStorage.getItem("user") == "" || localStorage.getItem("user") == null) redirect("/signin");
  }, []);

  return (
    <>
    {
    (localStorage.getItem("user") == "" || localStorage.getItem("user") == null) ? 
      redirect("/signin")
      :
      <Outlet />
    }
    </>
  );
}

export default CreatePost;