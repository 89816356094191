import React, { useState, useEffect } from 'react';
import './styles/condition.scss';

function Condition({ title, hasInput, buttonsObjects, status, onBackgroundClick, height }) 
{
  



  const [conditionInput, _setConditionInput] = useState("");


  useEffect(() => 
  {
    setupConditionInput("");
    status == undefined ? status = true : status = status;
  }, [])


  function setupConditionInput(value)
  {

      if ((value.charAt(0) != "#") && (value.charAt(1) != " "))
      {
          value = "# " + value;
      }
      else if ((value.charAt(0) == "#") && (value.charAt(1) != " "))
      {
          value = "# " + value.slice(1, value.length);
      }
      else if ((value.charAt(0) != "#") && (value.charAt(1) == " "))
      {
          value = "# " + value.slice(1, value.length);
      }

      let valueWithoutHashtag = value.slice(2, value.length);
      let deniedCharacters = ["#", "@", "*", ";", " "];


      for (let i = 0; i <= deniedCharacters.length; i += 1)
      {
          if (valueWithoutHashtag.charAt(valueWithoutHashtag.length - 1) == deniedCharacters[i])
          {
              valueWithoutHashtag = valueWithoutHashtag.slice(0, valueWithoutHashtag.length - 1);
              break;
          }
      }



      _setConditionInput("# " + valueWithoutHashtag);


  }

  function setFunctionallity(button)
  {
    console.log(button.onBackgroundClick);
    if (button.onClickAction != undefined) button.onClickAction(conditionInput.replace(" ", ""));
  }



  return (
    <>
        <div className="condition-bc" style={status == true ? height == undefined ? { } : { height: height } : { display: "none" }} onClick={onBackgroundClick}>
        
        </div>
    
        <div className="condition-container" style={status == true ? { display: "block" } : { display: "none" }}>
        <label style={{ fontSize: "30px", textAlign: "center" }}>{title}</label> <br></br> <br></br> <br></br>
        <div className="field"><input className="condition-input input-field" value={conditionInput} onInput={(e) => setupConditionInput(e.target.value)} style={hasInput == true ? { display: "block" } : { display: "none" }}></input></div> <br></br> <br></br> <br></br>  
        <div className="container-buttons">
            {
                buttonsObjects.map((value, index) => <button style={value.btnType == "allow" ? { backgroundColor: "rgb(51, 116, 255)" } : { backgroundColor: "rgb(255, 51, 51)" }} className="container-button" onClick={() => setFunctionallity(value)}>{value.title}</button>)
            }
        </div>
        </div>
    </>
  );
}

export default Condition;
